import React from 'react';
import './NavbarLogo.css';

const NavbarLogo = () => {
  return (
    <div className="navbar-logo-container">
      <div className="navbar-logo-text">planCoo</div>
      <div className="navbar-raindrops">
        <div className="navbar-raindrop"></div>
        <div className="navbar-raindrop"></div>
        <div className="navbar-raindrop"></div>
        <div className="navbar-raindrop"></div>
        <div className="navbar-raindrop"></div>
      </div>
    </div>
  );
};

export default NavbarLogo;
