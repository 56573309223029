import React from 'react';
import {
  Navbar as BootstrapNavbar,
  Nav,
  Container,
  NavDropdown,
} from 'react-bootstrap';
import { useUser } from '../../UserContext.js';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig.js';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NavBar.css';
import LanguageSelector from '../Language/LanguageSelector.js';
import NavbarLogo from './NavbarLogo.js';

const MyNavbar = () => {
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      console.log('Attempting to sign out...');
      await signOut(auth);
      setUser(null);
      localStorage.clear();
      console.log('Successfully signed out!');
      navigate('/LandingPage');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="navbar-wrapper">
      <BootstrapNavbar
        collapseOnSelect
        expand="lg"
        className="navbar"
        bg="light"
        variant="light"
      >
        <Container>
          <BootstrapNavbar.Brand as={NavLink} to="/">
            <NavbarLogo /> {/* Logo fungerer som lenke til landingpage */}
          </BootstrapNavbar.Brand>
          <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
          <BootstrapNavbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Link to="/home" className="nav-link">
                {t('portal')}
              </Link>
              <Link to="/subscriptions" className="nav-link">
                {t('subscriptions')}
              </Link>

              {/* Legal dropdown */}
              <NavDropdown title={t('legal')} id="legal-nav-dropdown">
                <NavDropdown.Item as={Link} to="/terms">
                  {t('terms_of_service')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/privacy">
                  {t('privacy_policy')}
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/dpa">
                  {t('data_processing_agreement')}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav className="align-items-center">
              {!user && (
                <Link to="/signup" className="nav-link">
                  {t('sign_up')}
                </Link>
              )}
              {user ? (
                <>
                  <NavDropdown
                    title={user.displayName}
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item as={Link} to="/SubscriptionManager">
                      {t('my_subscription')}
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={handleSignOut}>
                      {t('log_out')}
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : null}
              <LanguageSelector />
            </Nav>
          </BootstrapNavbar.Collapse>
        </Container>
      </BootstrapNavbar>
    </div>
  );
};

export default MyNavbar;
