import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useUserData, USER_QUERY_KEYS } from '../../../hooks/useUserProfile.js';
import { useUser } from '../../../UserContext.js';
import { useQueryClient } from 'react-query';
import ProfileModal from './ProfileModal.js';
import LoadingPage from '../../Spinner/LoadingPage.js';
import profilepic from '../../../assets/profilepic.png'; // Import default profile pic
import './Sidebar.css';

const Sidebar = ({ onProfileUpdate }) => {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { data: profileData, isLoading } = useUserData(user?.uid);
  const navigate = useNavigate();

  // Create normalized user data
  const currentUser = {
    uid: user?.uid,
    name: profileData?.name || user?.displayName,
    Company: profileData?.Company,
    profilepicurl: profileData?.profilepicurl || user?.photoURL,
    email: user?.email,
  };

  const handleEditProfile = () => {
    setShowProfileModal(true);
  };

  const handleModalClose = useCallback(async () => {
    const uid = user?.uid;
    if (uid) {
      try {
        await queryClient.invalidateQueries(USER_QUERY_KEYS.data(uid));
        await queryClient.refetchQueries({
          queryKey: USER_QUERY_KEYS.data(uid),
          exact: true,
        });
      } catch (error) {
        console.error('Error refreshing profile data:', error);
      }
    }
    setShowProfileModal(false);
    onProfileUpdate?.();
  }, [queryClient, user?.uid, onProfileUpdate]);

  const handleCompanyClick = () => {
    navigate('/company');
  };

  const handleGanttChartClick = () => {
    const isMobile = window.innerWidth <= 768;
    if (isMobile) {
      navigate('/mobile-projects');
    } else {
      navigate('/Gantt');
    }
  };

  const handleMobileProjectsClick = () => {
    navigate('/mobile-projects');
  };

  // Add cache-busting to image URL
  const imageUrl =
    currentUser.profilepicurl !== profilepic
      ? `${currentUser.profilepicurl}?t=${Date.now()}`
      : currentUser.profilepicurl;

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      {!isLoading && currentUser.uid && (
        <div className="sidebar-content">
          <div className="sidebar-profile-card">
            <div className="profile-image-container">
              <img
                src={imageUrl}
                alt={`${currentUser.name}'s profile`}
                className="profile-card-image"
                onError={(e) => {
                  e.target.src = profilepic;
                }}
              />
            </div>

            <div className="profile-details">
              <p className="profile-card-name">{currentUser.name}</p>
              {currentUser.Company && (
                <p className="profile-card-company">{currentUser.Company}</p>
              )}
              {currentUser.email && (
                <a
                  href={`mailto:${currentUser.email}`}
                  className="profile-card-email"
                  title="Get in touch via email"
                >
                  {currentUser.email}
                </a>
              )}
            </div>
            <div className="profile-actions">
              <button className="edit-profile-btn" onClick={handleEditProfile}>
                Edit Profile
              </button>
            </div>
          </div>

          <div className="sidebar-nav-container">
            <button onClick={handleCompanyClick} className="sidebar-nav-button">
              Company Settings
            </button>
            <button
              onClick={handleGanttChartClick}
              className="sidebar-nav-button"
            >
              Gantt Chart
            </button>
            <button
              onClick={handleMobileProjectsClick}
              className="sidebar-nav-button"
            >
              Mobile Projects
            </button>
          </div>
        </div>
      )}

      {showProfileModal && (
        <ProfileModal
          onClose={handleModalClose}
          profileData={profileData || currentUser}
        />
      )}
    </>
  );
};

Sidebar.propTypes = {
  onProfileUpdate: PropTypes.func,
};

export default Sidebar;
