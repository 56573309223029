import { signInWithPopup } from 'firebase/auth';
import { useQueryClient } from 'react-query';
import { auth, googleProvider, microsoftProvider } from '../firebaseConfig.js';
import axiosInstance from '../config/axios.js';
import AuthService from '../services/authService.js';
import logger from '../utils/logger.js';

// Flyttet fra firebaseConfig.js
export const useHandleUserCreation = () => {
  // Fix 1: Use queryClient in the function or add a comment to silence the warning
  const queryClient = useQueryClient();

  return async (user) => {
    logger.log(
      'useHandleUserCreation: Starting user creation for uid:',
      user.uid
    );
    try {
      // Signal that user creation has started
      AuthService.registerUserCreation(user.uid);

      const userData = {
        firebaseUserId: user.uid,
        email: user.email,
        name: user.displayName || user.email.split('@')[0],
        subscription_name: 'Gratis',
        subscription_status: 'inaktiv',
        subscription_end: new Date().toISOString(),
        company: '',
        profilepicurl: user.photoURL || '',
      };

      logger.log('useHandleUserCreation: Prepared user data:', userData);
      logger.log('useHandleUserCreation: Calling AddFirebaseUsers API');

      const response = await axiosInstance.post(
        '/api/AddFirebaseUsers',
        userData
      );

      // Actually use queryClient to invalidate any relevant queries
      queryClient.invalidateQueries(['userData', user.uid]);

      return response.data;
    } catch (error) {
      logger.error('Error creating user:', error);
      throw error;
    }
  };
};

export const useHandleUserPostSignIn = () => {
  const queryClient = useQueryClient();

  return async (user) => {
    // Fix 2: Remove unnecessary try/catch wrapper
    if (!user) {
      throw new Error('User is not authenticated.');
    }

    const userData = {
      uid: user.uid,
      email: user.email,
      name: user.displayName || 'My name',
      company: '',
      subscriptionStatus: 'inaktiv',
      subscriptionName: 'Gratis',
      profilePicUrl: user.photoURL || '',
    };

    queryClient.setQueryData('currentUser', userData);
  };
};

export const useHandleSignIn = () => {
  const handleUserCreation = useHandleUserCreation();
  const handleUserPostSignIn = useHandleUserPostSignIn();

  const signInWithProvider = async (
    provider,
    navigate,
    setMessage,
    setUser
  ) => {
    // Eksisterende implementasjon
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (!user.uid) throw new Error('Authentication failed');

      const isNewUser = result._tokenResponse?.isNewUser ?? false;

      if (isNewUser) {
        await handleUserCreation(user, setUser);
        setMessage('Welcome! Your account has been created.');
      } else {
        setMessage('Welcome back!');
        await handleUserPostSignIn(user);
      }

      navigate('/Home');
    } catch (error) {
      setMessage('Login failed. Please try again later.');
      throw error;
    }
  };

  const signInWithGoogle = (navigate, setMessage, setUser) =>
    signInWithProvider(googleProvider, navigate, setMessage, setUser);

  const signInWithMicrosoft = (navigate, setMessage, setUser) =>
    signInWithProvider(microsoftProvider, navigate, setMessage, setUser);

  return { signInWithGoogle, signInWithMicrosoft };
};
