import { useQuery, useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';
import { validateTaskData } from '../utils/validators.js';
import logger from '../utils/logger.js';

const useTasks = () => {
  return useQuery(
    QueryKeys.tasks.list(),
    async () => {
      try {
        const url = `/api/gettasks`;
        const response = await axiosInstance.get(url);

        if (!response.data) {
          throw new Error('No data received');
        }

        return response.data;
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          logger.error('Tasks fetch error:', error);
        }
        throw error;
      }
    },
    {
      staleTime: 5 * 60 * 1000, // 5 minutter
      cacheTime: 30 * 60 * 1000,
      retry: 1,
    }
  );
};

const useTaskMutations = () => {
  const queryClient = useQueryClient();

  const updateTasksMutation = useMutation(
    async (tasks) => {
      const validatedTasks = tasks.map(validateTaskData);

      if (process.env.NODE_ENV === 'development') {
        logger.log('Sending to API:', { taskCount: validatedTasks.length });
      }

      return await axiosInstance.put('/api/puttasks', {
        tasks: validatedTasks,
      });
    },
    {
      onMutate: async (updatedTasks) => {
        await queryClient.cancelQueries(QueryKeys.tasks.list());
        const previousTasks = queryClient.getQueryData(QueryKeys.tasks.list());

        queryClient.setQueryData(QueryKeys.tasks.list(), (old) =>
          (old || []).map(
            (task) =>
              updatedTasks.find((updatedTask) => updatedTask.id === task.id) ||
              task
          )
        );

        return { previousTasks };
      },
      onError: (err, variables, context) => {
        queryClient.setQueryData(QueryKeys.tasks.list(), context.previousTasks);
        if (process.env.NODE_ENV === 'development') {
          logger.error('Error updating tasks:', err);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(QueryKeys.tasks.list());
      },
    }
  );

  const deleteTaskMutation = useMutation(
    async (taskId) => {
      // Valider task ID
      if (
        !taskId ||
        (typeof taskId !== 'string' && typeof taskId !== 'number')
      ) {
        throw new Error('Invalid task ID');
      }
      return await axiosInstance.delete(
        `/api/deletetask/${encodeURIComponent(taskId)}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.tasks.list());
      },
      onError: (err) => {
        if (process.env.NODE_ENV === 'development') {
          logger.error('Error deleting task:', err);
        }
      },
    }
  );

  const createTasksMutation = useMutation(
    async (tasks) => {
      const validatedTasks = tasks.map(validateTaskData);
      return await axiosInstance.post('/api/posttasks', {
        tasks: validatedTasks,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.tasks.list());
      },
      onError: (err) => {
        if (process.env.NODE_ENV === 'development') {
          logger.error('Error creating tasks:', err);
        }
      },
    }
  );

  return {
    updateTasks: updateTasksMutation,
    deleteTask: deleteTaskMutation,
    createTasks: createTasksMutation,
  };
};

export { useTasks, useTaskMutations };
