import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSearchUsers from '../../hooks/useSearchUsers.js';
import profilepic from '../../assets/profilepic.png';
import { IoClose } from 'react-icons/io5';
import './TaskAssignee.css';

const TaskAssignee = ({
  currentAssignee = null,
  onAssigneeSelect,
  context = 'default',
}) => {
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults, handleSearch } = useSearchUsers();

  // Load users when modal opens
  useEffect(() => {
    if (showModal) {
      try {
        handleSearch('');
      } catch (error) {
        // Stille håndtering av søkefeil for å unngå krasj
        console.error('Search error:', error);
      }
    }
  }, [showModal, handleSearch]);

  const handleSearchChange = (e) => {
    // Begrens søketekst til 100 tegn for å unngå DoS
    const searchValue = e.target.value.slice(0, 100);
    setSearchTerm(searchValue);
    handleSearch(searchValue);
  };

  const handleSelectUser = (user) => {
    // Valider bruker før den sendes videre
    if (!user || !user.uid) return;

    // Sanitize user data for XSS prevention
    const sanitizedUser = {
      uid: typeof user.uid === 'string' ? user.uid : String(user.uid),
      name: user.name ? String(user.name).slice(0, 100) : 'Unknown User',
      profilepicurl: user.profilepicurl || null,
      Company: user.Company ? String(user.Company).slice(0, 100) : null,
    };

    onAssigneeSelect(sanitizedUser);
    setSearchTerm('');
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
    setSearchTerm('');
  };

  // Sanitize current assignee data
  const safeAssignee = currentAssignee
    ? {
        name: currentAssignee.name
          ? String(currentAssignee.name).slice(0, 100)
          : 'Not assigned',
        uid: currentAssignee.uid,
        profilepicurl: currentAssignee.profilepicurl,
        Company: currentAssignee.Company
          ? String(currentAssignee.Company).slice(0, 100)
          : null,
      }
    : null;

  return (
    <div className={`assignee-container ${context}-context`}>
      {/* Current assignee display */}
      <div className="current-assignee" onClick={() => setShowModal(true)}>
        <img
          src={safeAssignee?.profilepicurl || profilepic}
          alt={safeAssignee?.name || 'Not assigned'}
          className="assignee-avatar"
          onError={(e) => {
            e.target.src = profilepic;
          }}
        />
        <div className="assignee-info">
          <span className="assignee-name">
            {safeAssignee?.name || 'Click to assign'}
          </span>
          {safeAssignee?.Company && (
            <span className="assignee-company">{safeAssignee.Company}</span>
          )}
        </div>
      </div>

      {/* Modal overlay for search */}
      {showModal && (
        <div className="assignee-modal-backdrop" onClick={handleClose}>
          <div className="assignee-modal" onClick={(e) => e.stopPropagation()}>
            <div className="assignee-modal-header">
              <h4>Select User</h4>
              <button
                className="modal-close-btn"
                onClick={handleClose}
                aria-label="Close dialog"
              >
                <IoClose size={20} />
              </button>
            </div>

            <div className="assignee-modal-search">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search for user..."
                className="modal-search-input"
                maxLength={100}
                autoFocus
              />
            </div>

            <div className="assignee-modal-results">
              {Array.isArray(searchResults) && searchResults.length > 0 ? (
                searchResults.map((user) => (
                  <div
                    key={user.uid || `${user.name}-${user.Company}`}
                    className="modal-result-item"
                    onClick={() => handleSelectUser(user)}
                  >
                    <img
                      src={user.profilepicurl || profilepic}
                      alt={user.name || 'User'}
                      className="result-avatar"
                      onError={(e) => {
                        e.target.src = profilepic;
                      }}
                    />
                    <div className="result-details">
                      <span className="result-name">
                        {user.name || 'Unknown User'}
                      </span>
                      <span className="result-company">
                        {user.Company || ''}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-results">No users found</div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TaskAssignee.propTypes = {
  currentAssignee: PropTypes.shape({
    name: PropTypes.string,
    uid: PropTypes.string,
    profilepicurl: PropTypes.string,
    Company: PropTypes.string,
  }),
  onAssigneeSelect: PropTypes.func.isRequired,
  context: PropTypes.string,
};

export default TaskAssignee;
