import React, { useState, useEffect } from 'react';
import './LoadingPage.css';

const LoadingPage = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Progress bar simulation
    const progressInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return prev + Math.random() * 15;
      });
    }, 1000);

    return () => {
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div className="loading-page">
      <div className="loading-content">
        <div className="loading-logo">planCoo</div>
        <div className="raindrops">
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
          <div className="raindrop"></div>
        </div>
      </div>

      <div className="loading-info">
        <div className="loading-message">
          A startup from Bergen, where the clouds never stop delivering
        </div>
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${Math.min(progress, 100)}%` }}
          ></div>
        </div>
        <div className="progress-text">
          {Math.min(Math.round(progress), 100)}%
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
