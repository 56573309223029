import React, { useState } from 'react';
import { useUser } from '../../UserContext.js';
import { useUserData } from '../../hooks/useUserProfile.js';
import { Card, Button, Row, Col, Badge, Alert } from 'react-bootstrap';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './SubscriptionManager.css';

const SubscriptionManager = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { data: userProfile } = useUserData(user?.uid);
  const [processingAction, setProcessingAction] = useState(false);

  // Brukerdata
  const subscription = userProfile
    ? {
        name: userProfile.subscription_name,
        status: userProfile.subscription_status,
        endsAt: userProfile.subscription_end,
        createdAt: userProfile.createdAt,
        customerId: userProfile.customerId,
      }
    : null;

  // Håndterer åpning av kundeportalen
  const handleManageSubscription = async () => {
    setProcessingAction(true);
    try {
      window.open('https://app.lemonsqueezy.com/my-orders/login', '_blank');
    } catch (error) {
      console.error('Error opening customer portal:', error);
    } finally {
      setProcessingAction(false);
    }
  };

  // Ingen abonnement
  if (!subscription) {
    return (
      <div className="subscription-manager-container">
        <Card className="subscription-card">
          <Card.Body>
            <Card.Title>{t('no_active_subscription')}</Card.Title>
            <Card.Text>{t('subscription_needed_text')}</Card.Text>
            <Button variant="primary" href="/pricing">
              {t('view_pricing')}
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  }

  return (
    <div className="subscription-manager-container">
      <Card className="subscription-card">
        <Card.Body>
          <Card.Title>{t('Subscription Status')}</Card.Title>

          <Row className="mb-4 mt-4">
            <Col xs={12} md={6}>
              <div className="subscription-detail">
                <h5>{t('Price plan')}</h5>
                <p className="value">
                  {subscription.name === 'business'
                    ? 'PlanCoo Business'
                    : 'PlanCoo User'}
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="subscription-detail">
                <h5>{t('Status')}</h5>
                <Badge
                  bg={
                    subscription.status === 'active'
                      ? 'success'
                      : subscription.status === 'cancelled'
                        ? 'danger'
                        : 'warning'
                  }
                >
                  {subscription.status === 'active'
                    ? t('active')
                    : subscription.status === 'cancelled'
                      ? t('cancelled')
                      : t('inactive')}
                </Badge>
              </div>
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xs={12} md={6}>
              <div className="subscription-detail">
                <h5>{t('Renewal date')}</h5>
                <p className="value">
                  {subscription.endsAt
                    ? new Date(subscription.endsAt).toLocaleDateString()
                    : t('not_applicable')}
                </p>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="subscription-detail">
                <h5>{t('Customer since')}</h5>
                <p className="value">
                  {new Date(subscription.createdAt).toLocaleDateString()}
                </p>
              </div>
            </Col>
          </Row>

          {/* Lemon Squeezy Kundeportal Info */}
          <Alert variant="info" className="mt-4 subscription-portal-info">
            <Alert.Heading>{t('Manage Your Subscription')}</Alert.Heading>
            <p>
              To make changes to your subscription, including upgrading,
              cancelling or updating payment information, please visit your
              customer dashboard in Lemon Squeezy. Our secure payment provider
              handles all billing operations to ensure your information remains
              protected.
            </p>
            <hr />
            <div className="d-flex justify-content-center">
              <Button
                variant="primary"
                size="lg"
                className="mt-2 customer-portal-button"
                onClick={handleManageSubscription}
                disabled={processingAction}
              >
                {processingAction ? (
                  t('processing')
                ) : (
                  <>
                    {t('Go to Customer Dashboard')}{' '}
                    <FaExternalLinkAlt className="ms-2" />
                  </>
                )}
              </Button>
            </div>
          </Alert>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SubscriptionManager;
