import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import logger from './utils/logger.js';
import { TokenService } from './services/tokenService.js';

// Firebase-konfigurasjon
const createFirebaseConfig = () => {
  const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: 'planningtool-dad.firebaseapp.com',
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
  };

  // Valider config
  const required = ['apiKey', 'authDomain', 'projectId'];
  const missing = required.filter((key) => !config[key]);

  if (missing.length > 0) {
    throw new Error(`Missing required Firebase config: ${missing.join(', ')}`);
  }

  return config;
};

// Initialiser Firebase
const firebaseConfig = createFirebaseConfig();
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Set longer persistence to reduce re-authentication
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    logger.log('Firebase persistence mode set successfully');
  })
  .catch((error) => {
    logger.error('Firebase persistence error:', error);
  });

// Google-autentisering
export const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
googleProvider.addScope('email');
googleProvider.addScope('profile');

// Microsoft-autentisering
export const microsoftProvider = new OAuthProvider('microsoft.com');
microsoftProvider.setCustomParameters({
  prompt: 'select_account',
  tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
  domain_hint: 'organizations',
});
microsoftProvider.addScope('openid');
microsoftProvider.addScope('profile');
microsoftProvider.addScope('email');

// Sett opp token-lytteren direkte her
auth.onIdTokenChanged((user) => {
  if (user) {
    user
      .getIdToken(true)
      .then((token) => {
        TokenService.setToken(token);
      })
      .catch((error) => {
        logger.error('Failed to retrieve auth token:', error);
        TokenService.clearToken();
      });
  } else {
    TokenService.clearToken();
  }
});

logger.log('Firebase initialized successfully');

// Reeksporter hooks fra useAuth.js
export {
  useHandleUserCreation,
  useHandleUserPostSignIn,
  useHandleSignIn,
} from './hooks/useAuth.js';
