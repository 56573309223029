import logger from '../utils/logger.js';
import { useQuery } from 'react-query';
import { useEffect } from 'react';
import { auth } from '../firebaseConfig.js';
import { signOut } from 'firebase/auth';

// Add this function for handling auth errors
export const handleAuthError = async (error) => {
  logger.error('Authentication error:', error);

  try {
    // Clear all auth storage
    localStorage.removeItem(
      'firebase:authUser:' +
        process.env.REACT_APP_FIREBASE_API_KEY +
        ':[DEFAULT]'
    );
    sessionStorage.removeItem(
      'firebase:authUser:' +
        process.env.REACT_APP_FIREBASE_API_KEY +
        ':[DEFAULT]'
    );

    // Sign out the user
    await signOut(auth);

    // Redirect to login page
    window.location.href = '/Login';
  } catch (signOutError) {
    logger.error('Error during sign out:', signOutError);
    // Force redirect even if signOut fails
    window.location.href = '/Login';
  }
};

// Token storage with expiration
export const secureSessionStorage = {
  set: (key, value, expiresInMs = 3600000) => {
    const item = {
      value,
      expires: Date.now() + expiresInMs,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  },
  get: (key) => {
    const item = JSON.parse(sessionStorage.getItem(key) || 'null');
    if (!item) return null;
    if (Date.now() > item.expires) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
  remove: (key) => {
    sessionStorage.removeItem(key);
  },
};

// Debouncing variabler
let tokenUpdateTimeout = null;
let lastToken = null;

// Stale time på 40 minutter (i millisekunder)
const TOKEN_STALE_TIME = 40 * 60 * 1000;

// Token-fetcher funksjon
const fetchToken = async () => {
  const user = auth.currentUser;
  if (!user) return null;

  try {
    return await user.getIdToken(false);
  } catch (error) {
    logger.error('Error fetching token:', error);
    return null;
  }
};

// Central token manager
export class TokenService {
  static _currentToken = null;
  static _tokenUpdateCallbacks = [];
  static _initialized = false;
  static _refreshAttempts = 0;
  static _maxRefreshAttempts = 3;
  static _refreshCooldown = false;
  static _refreshInterval = null;
  static _userHasBeenActiveRecently = true;
  static _activityInitialized = false;

  // Register for token updates
  static onTokenUpdate(callback) {
    this._tokenUpdateCallbacks.push(callback);
    return () => {
      this._tokenUpdateCallbacks = this._tokenUpdateCallbacks.filter(
        (cb) => cb !== callback
      );
    };
  }

  // Set token med debouncing
  static setToken(token) {
    // Skip hvis token er uendret
    if (token === lastToken) return;

    // Avbryt eventuelle ventende oppdateringer
    if (tokenUpdateTimeout) clearTimeout(tokenUpdateTimeout);

    // Vent 50ms før vi oppdaterer UI
    tokenUpdateTimeout = setTimeout(() => {
      this._currentToken = token;
      lastToken = token;

      if (token) {
        logger.log('Token updated');
      } else {
        logger.log('Token cleared');
      }

      // Notifiser alle abonnenter
      this._tokenUpdateCallbacks.forEach((callback) => {
        try {
          callback(token);
        } catch (error) {
          logger.error('Error in token update callback:', error);
        }
      });
    }, 50);
  }

  // Get current token
  static getToken() {
    return this._currentToken;
  }

  // Clear token
  static clearToken() {
    this.setToken(null);
  }

  // Sett opp forbedret token-håndtering
  static setupTokenRefresh() {
    // Cancel any existing interval first
    this.clearTokenRefresh();

    // Set up a timer to check token expiration - but less frequently
    this._refreshInterval = setInterval(async () => {
      try {
        // Only refresh if user is active (add activity tracking)
        if (this._userHasBeenActiveRecently) {
          const tokenResult = await auth.currentUser?.getIdTokenResult();
          if (tokenResult) {
            const expirationTime = new Date(
              tokenResult.expirationTime
            ).getTime();
            const now = Date.now();

            // Only refresh when token is closer to expiration (15 min instead of 5)
            if (expirationTime - now < 15 * 60 * 1000) {
              logger.log('Token refreshing due to upcoming expiration');
              const newToken = await auth.currentUser.getIdToken(true);
              this.setToken(newToken);
            }
          }
        }
      } catch (error) {
        logger.error('Failed to check token expiration:', error);
      }
    }, 300000); // Check every 5 minutes instead of every minute
  }

  // Track user activity to avoid refreshing tokens for inactive users
  static initActivityTracking() {
    this._userHasBeenActiveRecently = true;

    // Reset activity flag after inactivity
    const resetActivity = () => {
      this._userHasBeenActiveRecently = true;
    };

    // Add event listeners only once
    if (!this._activityInitialized) {
      window.addEventListener('mousemove', resetActivity);
      window.addEventListener('keydown', resetActivity);
      window.addEventListener('click', resetActivity);
      window.addEventListener('touchstart', resetActivity);

      // Set inactive after 30 minutes of no activity
      setInterval(
        () => {
          this._userHasBeenActiveRecently = false;
        },
        30 * 60 * 1000
      );

      this._activityInitialized = true;
    }
  }

  // Be sure to clear the interval when appropriate
  static clearTokenRefresh() {
    if (this._refreshInterval) {
      clearInterval(this._refreshInterval);
      this._refreshInterval = null;
    }
  }

  // Reset attempts periodically
  static resetAttempts() {
    setTimeout(() => {
      this._refreshAttempts = 0;
      this._refreshCooldown = false;
    }, 60000); // Reset after 1 minute
  }

  static async refreshToken() {
    // Check if we're in cooldown or exceeded attempts
    if (
      this._refreshCooldown ||
      this._refreshAttempts >= this._maxRefreshAttempts
    ) {
      console.warn('Token refresh blocked - too many attempts');

      if (!this._refreshCooldown) {
        this._refreshCooldown = true;
        this.resetAttempts();
        // Force sign out when we hit the limit
        await handleAuthError({
          status: 400,
          message: 'Too many refresh attempts',
        });
      }

      return Promise.reject(new Error('Token refresh rate limited'));
    }

    this._refreshAttempts++;

    try {
      // Get the current user
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No authenticated user');
      }

      // Force token refresh
      const newToken = await user.getIdToken(true);

      // Update the token in our service
      this.setToken(newToken);

      // Reset the attempt counter on success
      this._refreshAttempts = 0;

      return newToken;
    } catch (error) {
      logger.error('Token refresh failed:', error);

      // Add additional error code checks
      if (
        error.code === 'auth/requires-recent-login' ||
        error.code === 'auth/id-token-revoked' ||
        error.code === 'auth/user-token-expired' ||
        error.code === 'auth/internal-error' ||
        error.code === 'auth/invalid-credential' ||
        error.code === 'auth/network-request-failed' ||
        error.message?.includes('token') || // Generic token errors
        error.message?.includes('auth') // Generic auth errors
      ) {
        // Call our new method instead
        this.forceSignOut();
      }

      return Promise.reject(error);
    }
  }

  // Add this method to TokenService class
  static forceSignOut() {
    logger.warn('Forcing user sign out due to authentication issues');

    // Clear all auth state
    this.clearToken();
    localStorage.clear(); // Clear all localStorage to be thorough
    sessionStorage.clear(); // Clear all sessionStorage

    // Sign out and redirect
    signOut(auth)
      .catch((error) => logger.error('Error during forced sign out:', error))
      .finally(() => {
        // Always redirect, even if signOut fails
        window.location.href = '/Login?reason=session_expired';
      });
  }

  // Add this to your TokenService class
  static isTokenExpired(bufferSeconds = 300) {
    try {
      // Get current token if any
      const token = this.getToken();
      if (!token) return true;

      // Parse token (JWT format: header.payload.signature)
      const parts = token.split('.');
      if (parts.length !== 3) return true;

      // Decode payload
      const payload = JSON.parse(atob(parts[1]));
      if (!payload.exp) return true;

      // Check if expired or expiring soon
      const now = Math.floor(Date.now() / 1000);
      return payload.exp - now < bufferSeconds;
    } catch (e) {
      console.error('Error checking token expiration:', e);
      return true; // Assume expired on error
    }
  }
}

// React Hook for å bruke tokenet
export const useAuthToken = () => {
  const result = useQuery(['authToken'], fetchToken, {
    staleTime: TOKEN_STALE_TIME,
    cacheTime: TOKEN_STALE_TIME,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    onSuccess: (token) => {
      if (token) TokenService.setToken(token);
    },
  });

  // Sett opp token-oppdatering ved første rendering
  useEffect(() => {
    TokenService.setupTokenRefresh();
    TokenService.initActivityTracking();
  }, []);

  return result;
};
