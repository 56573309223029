import axiosInstance from '../config/axios.js';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import AuthService from './authService.js';
import logger from '../utils/logger.js'; // Import the logger utility

export const fetchUserDetails = async (uid, options = {}) => {
  if (!uid) {
    logger.warn('No UID provided to fetchUserDetails');
    throw new Error('User ID is required');
  }

  // Default options
  const {
    waitForUserCreation = true,
    maxWaitTime = 10000, // Maximum time to wait for user creation (10 seconds)
    checkInterval = 500, // Check every 500ms
    ...retryOptions // Keep existing retry options
  } = options;

  // Check if user creation is in progress for this UID
  if (waitForUserCreation && AuthService.isUserCreationInProgress(uid)) {
    logger.log(
      `User creation in progress for ${uid}, waiting before fetching details...`
    );

    // Wait for creation to complete with timeout
    const startTime = Date.now();

    while (AuthService.isUserCreationInProgress(uid)) {
      // Check if we've exceeded the maximum wait time
      if (Date.now() - startTime > maxWaitTime) {
        logger.warn(`Timeout waiting for user creation to complete for ${uid}`);
        break;
      }

      // Wait for a bit before checking again
      await new Promise((resolve) => setTimeout(resolve, checkInterval));
    }

    // Give a small additional delay for any database operations to finalize
    await new Promise((resolve) => setTimeout(resolve, 500));
    logger.log(
      `Finished waiting for user creation, proceeding to fetch user data`
    );
  }

  // Now check if this user was recently created successfully
  if (AuthService.wasUserRecentlyCreated(uid)) {
    logger.log(`User ${uid} was recently created successfully`);
    // We could potentially avoid the fetch completely if we have cached data
  }

  // Continue with existing retry logic for additional robustness
  return fetchWithRetryLogic(uid, retryOptions);
};

// Extract the retry logic to a separate function
const fetchWithRetryLogic = async (uid, options) => {
  const {
    isNewUser = false,
    maxRetries = 3,
    retryDelay = 1000,
    backoffFactor = 2,
  } = options;

  let retryCount = 0;

  // Function to handle retry with exponential backoff
  const fetchWithRetry = async () => {
    try {
      const url = `/api/userdata/${uid}`;
      logger.log(`Fetching user details from: ${url}`);

      const response = await axiosInstance.get(url);
      logger.log('User profile data received successfully');

      // Clear any creation flags since we've successfully fetched the user
      if (AuthService.wasUserRecentlyCreated(uid)) {
        localStorage.removeItem('userCreationComplete');
        localStorage.removeItem('userCreationCompleteTime');
      }

      return response.data;
    } catch (error) {
      // Same retry logic as before for additional robustness
      if (
        error.response?.status === 404 &&
        (isNewUser || AuthService.wasUserRecentlyCreated(uid)) &&
        retryCount < maxRetries
      ) {
        retryCount++;
        const delay = retryDelay * Math.pow(backoffFactor, retryCount - 1);

        logger.log(
          `User data not found yet, retrying in ${delay}ms (attempt ${retryCount}/${maxRetries})`
        );

        await new Promise((resolve) => setTimeout(resolve, delay));
        return fetchWithRetry();
      }

      // Error handling as before
      logger.error(`Failed to fetch user details: ${error.message}`);
      if (error.response) {
        logger.log(`Response status: ${error.response.status}`);
        logger.log(`Response data: ${error.response.data}`);
      }
      throw error;
    }
  };

  return fetchWithRetry();
};

export const useUserDetails = (uid, options = {}) => {
  // Check if this user's creation is in progress
  const creationInProgress = AuthService.isUserCreationInProgress(uid);

  return useQuery(['userDetails', uid], () => fetchUserDetails(uid, options), {
    enabled: !!uid && !creationInProgress, // Don't run the query until creation is complete
    retry: 2,
    retryDelay: 1000,
    onError: (error) => {
      logger.error(`User details query error for ${uid}:`, error);
    },
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (userData) => {
      try {
        // Konverter data til format som AddFirebaseUsers forventer
        const apiData = {
          firebaseUserId: userData.firebaseUserId,
          email: userData.email,
          name: userData.name || userData.email.split('@')[0],
          company: userData.company || '',
          subscription_name: userData.subscription_name || 'Gratis',
          subscription_status: userData.subscription_status || 'inaktiv',
          subscription_end:
            userData.subscription_end || new Date().toISOString(),
          profilepicurl: userData.profilepicurl || '',
          customerId: userData.customerId || null,
        };

        logger.log('Creating user with data:', apiData);

        // Bruk AddFirebaseUsers istedenfor HandleUserEvent
        const response = await axiosInstance.post(
          '/api/AddFirebaseUsers',
          apiData
        );
        logger.log('User creation response:', response.data);

        // Wait before fetching updated user data
        await new Promise((resolve) => setTimeout(resolve, 2000));

        try {
          const userResponse = await axiosInstance.get(
            `/api/userdata/${userData.firebaseUserId}`
          );
          return userResponse.data;
        } catch (userError) {
          logger.warn('Failed to fetch newly created user:', userError);
          return response.data;
        }
      } catch (error) {
        logger.error('User creation error:', error);
        throw error;
      }
    },
    {
      onSuccess: (data, variables) => {
        queryClient.setQueryData(
          ['userProfile', variables.firebaseUserId],
          data
        );
        queryClient.setQueryData(
          ['userDetails', variables.firebaseUserId],
          data
        );
        queryClient.invalidateQueries(['userProfile']);
      },
    }
  );
};
