import { useMutation } from 'react-query';
import axios from '../config/axios.js';
import logger from '../utils/logger.js'; // Import the logger utility

export const useProfilePicture = () => {
  return useMutation(async ({ file, userId }) => {
    try {
      // 1. Hent SAS URL
      const sasResponse = await axios.get('/api/profilepictures', {
        params: {
          fileName: file.name.replace(/\s+/g, ''), // Remove spaces from filename
          userId: userId,
        },
      });

      logger.log('SAS Response:', sasResponse.data);

      if (!sasResponse.data?.sasUrl) {
        throw new Error('Invalid SAS URL received');
      }

      // 2. Last opp til blob storage
      const uploadResponse = await fetch(sasResponse.data.sasUrl, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': file.type || 'image/jpeg',
        },
        body: file,
      });

      if (!uploadResponse.ok) {
        throw new Error(`Upload failed: ${uploadResponse.statusText}`);
      }

      // 3. Use CDN URL from response or construct fallback
      const cdnUrl =
        sasResponse.data.cdnUrl ||
        `https://plancooblobcdn.azureedge.net/profile-pictures/${sasResponse.data.blobName}`;

      logger.log('Using CDN URL:', cdnUrl);
      return cdnUrl;
    } catch (error) {
      logger.error('Profile picture upload error:', error);
      throw error;
    }
  });
};
