import { signOut, getAuth } from 'firebase/auth';
import { auth } from '../firebaseConfig.js';
import { secureSessionStorage, TokenService } from './tokenService.js';
import logger from '../utils/logger.js';

class AuthService {
  // Get current user
  static getCurrentUser() {
    return auth.currentUser;
  }

  // Check if user is authenticated
  static isAuthenticated() {
    return !!auth.currentUser;
  }

  // Cleanup local tokens
  static cleanup() {
    secureSessionStorage.remove('firebaseToken');
    secureSessionStorage.remove('token');
    secureSessionStorage.remove('uid');
    TokenService.clearToken();
  }

  // Handle logout
  static async logout() {
    try {
      this.cleanup();
      await signOut(auth);
    } catch (error) {
      logger.error('Logout error:', error);
      throw error;
    }
  }

  // User creation state methods
  static registerUserCreation(uid) {
    secureSessionStorage.set('userCreationInProgress', uid, 600000); // 10 min expiration
    secureSessionStorage.set('userCreationStartTime', Date.now(), 600000);
  }

  static isUserCreationInProgress() {
    return !!secureSessionStorage.get('userCreationInProgress');
  }

  static getUserCreationUid() {
    return secureSessionStorage.get('userCreationInProgress');
  }

  static markUserCreationComplete(uid) {
    secureSessionStorage.remove('userCreationInProgress');
    secureSessionStorage.set('userCreationComplete', uid, 600000);
    secureSessionStorage.set('userCreationCompleteTime', Date.now(), 600000);
  }

  static wasUserRecentlyCreated(uid) {
    return secureSessionStorage.get('userCreationComplete') === uid;
  }
}

// Add this function to handle invalid tokens
export const handleAuthError = async (error) => {
  // Check if it's a token-related error
  if (
    error.code === 'auth/invalid-credential' ||
    error.code === 'auth/user-token-expired' ||
    error.code === 'auth/requires-recent-login' ||
    error.status === 400 || // For the 400 Bad Request you're getting
    error.status === 401 // For the 401 Unauthorized
  ) {
    const auth = getAuth();

    // Clear all authentication state
    localStorage.removeItem('firebase:authUser');
    localStorage.removeItem('firebase:token');
    sessionStorage.removeItem('firebase:authUser');
    sessionStorage.removeItem('firebase:token');

    // Sign out the user
    await signOut(auth);

    // Redirect to login page
    window.location.href = '/login';
  }
};

export default AuthService;
