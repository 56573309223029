import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ProfileCardContainer from './ProfileCardContainer.js';
import { useFollowers } from '../../../hooks/useFollowers.js';
import { useUser } from '../../../UserContext.js';
import { useFollowing } from '../../../hooks/useFollowing.js';
import './SocialCards.css'; // Import our new CSS file
import LoadingPage from '../../Spinner/LoadingPage.js';
import axiosInstance from '../../../config/axios.js';

const API_UNFOLLOW = '/api/unfollowuser'; // Corrected endpoint URL

const Followers = ({ uid, onUpdateFollowing }) => {
  const { user, isLoading: userLoading } = useUser();
  const { t } = useTranslation();

  const effectiveUid = user?.uid || uid;
  const { data: followingList = [] } = useFollowing(effectiveUid);
  const {
    data: followers = [],
    isLoading: followersLoading,
    refetch: refetchFollowers,
  } = useFollowers(effectiveUid);

  const handleUpdateFollowing = useCallback(
    async (isFollowing, targetUserId) => {
      try {
        if (!isFollowing) {
          await axiosInstance.delete(API_UNFOLLOW, {
            data: { followerUid: effectiveUid, followedUid: targetUserId },
          });

          await refetchFollowers();
          onUpdateFollowing?.(false, targetUserId);
        }
      } catch (error) {
        console.error('Unfollow error:', error);
        throw error;
      }
    },
    [effectiveUid, onUpdateFollowing, refetchFollowers]
  );

  if (userLoading || followersLoading) {
    return <LoadingPage />;
  }

  if (!effectiveUid) {
    return null;
  }

  return (
    <>
      {Array.isArray(followers) && followers.length > 0 && (
        <div className="following-section">
          <h3>{t('People who follow you')}</h3>
          {followers.map((follower) => {
            // Add validation before rendering
            if (!follower?.uid || !follower?.name) {
              console.warn('Invalid user data:', {
                uid: follower?.uid,
                name: follower?.name,
                fullData: follower,
              });
              return null;
            }

            const isFollowing = followingList.some(
              (following) => following.uid === follower.uid
            );

            return (
              <ProfileCardContainer
                key={follower.uid}
                user={{
                  ...follower,
                  uid: follower.uid,
                  name: follower.name, // Ensure name is passed
                  profilepicurl: follower.profilepicurl || follower.photoURL,
                  Company: follower.Company || follower.company,
                  isFollowing,
                }}
                currentUserId={effectiveUid}
                className="followers-card"
                onUpdateFollowing={handleUpdateFollowing}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

Followers.propTypes = {
  uid: PropTypes.string, // Make optional since we use effectiveUid
  onUpdateFollowing: PropTypes.func.isRequired,
};

export default React.memo(Followers);
