import { useQuery } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';
import { useUser } from '../UserContext.js';
import logger from '../utils/logger.js'; // Import the logger utility

const useFollowers = (uid) => {
  const { isLoading: userLoading } = useUser();

  return useQuery(
    QueryKeys.followers.list(uid),
    async () => {
      if (userLoading || !uid) {
        return [];
      }

      try {
        const url = `/api/getfollowers/${uid}`;
        const response = await axiosInstance.get(url);

        const followersData = response.data.map((user) => ({
          uid: user.uid || user.followerUid,
          name: user.name || user.user_name || user.username || 'Unknown User', // Added more fallbacks
          Company: user.Company || user.company || user.user_company,
          profilepicurl:
            user.profilepicurl || user.photoURL || user.user_profilepic,
          email: user.email,
          isFollowing: Boolean(user.isFollowing || user.following),
        }));

        return followersData;
      } catch (error) {
        logger.error('Followers fetch error:', error.message);
        throw error;
      }
    },
    {
      enabled: !userLoading && Boolean(uid),
      staleTime: 0,
      cacheTime: 30 * 60 * 1000,
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      onError: (error) => {
        if (!userLoading) {
          logger.error(
            'Followers fetch error:',
            error.response?.data || error.message
          );
        }
      },
    }
  );
};

export { useFollowers };
