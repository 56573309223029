import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoImageOutline, IoSendOutline } from 'react-icons/io5';
import { MdCancel, MdOutlinePostAdd } from 'react-icons/md';
import LoadingPage from '../../Spinner/LoadingPage.js';
import './CreatePostForm.css';
import { toast } from 'react-toastify';

const CreatePostForm = ({ handleImageUpload, loading }) => {
  const [newPost, setNewPost] = useState({ description: '', feedImage: null });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handlePostChange = (e) => {
    const { name, value } = e.target;
    setNewPost((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewPost((prevPost) => ({
        ...prevPost,
        feedImage: file,
      }));
      setSelectedFile(file);
    }
  };

  const resetForm = () => {
    setNewPost({ description: '', feedImage: null });
    setSelectedFile(null);
  };

  const handleSubmit = async () => {
    if (!newPost.feedImage) {
      toast.error('Please select an image');
      return;
    }
    await handleImageUpload(newPost);
    resetForm();
    setIsExpanded(false); // Collapse after posting
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      // Focus the textarea when expanding
      setTimeout(() => {
        const textarea = document.querySelector('.create-post-textarea');
        if (textarea) textarea.focus();
      }, 300); // Delay to wait for animation
    }
  };

  return (
    <div
      className={`create-post-form ${isExpanded ? 'expanded' : 'collapsed'}`}
    >
      {!isExpanded ? (
        <button
          className="create-post-toggle-btn"
          onClick={toggleExpand}
          aria-label="Create new post"
        >
          <MdOutlinePostAdd size={22} />
          <span>Create Post</span>
        </button>
      ) : (
        <>
          <textarea
            name="description"
            placeholder="What's on your mind?"
            value={newPost.description}
            onChange={handlePostChange}
            className="create-post-textarea"
          />
          <div className="button-container-icons">
            <label htmlFor="file-upload" className="btn-icon" title="Add image">
              <IoImageOutline size={24} />
            </label>
            <input
              id="file-upload"
              type="file"
              name="feedImage"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            {selectedFile && (
              <span className="file-name">{selectedFile.name}</span>
            )}

            <button
              onClick={handleSubmit}
              className="btn-icon"
              disabled={
                loading || !newPost.description.trim() || !newPost.feedImage
              }
              title="Publish post"
            >
              {loading ? <LoadingPage /> : <IoSendOutline size={24} />}
            </button>

            {newPost.description || newPost.feedImage ? (
              <button
                onClick={resetForm}
                className="btn-icon cancel-button"
                disabled={loading}
                title="Cancel"
              >
                <MdCancel size={24} />
              </button>
            ) : (
              <button
                onClick={toggleExpand}
                className="btn-icon cancel-button"
                title="Close"
              >
                <MdCancel size={24} />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

CreatePostForm.propTypes = {
  handleImageUpload: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CreatePostForm;
