import React from 'react';
import PropTypes from 'prop-types';
import './MicrosoftBtn.css';
import MicrosoftIcon from '../../../assets/Auth/Microsoft.svg';

const MicrosoftBtn = ({ onClick, disabled = false }) => {
  return (
    <button
      className="custom-microsoft-btn"
      onClick={onClick}
      disabled={disabled}
    >
      <img
        src={MicrosoftIcon}
        alt="Microsoft sign-in button"
        className="btn-icon"
      />
    </button>
  );
};

MicrosoftBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MicrosoftBtn;
