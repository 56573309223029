import axios from 'axios';
import logger from '../utils/logger.js';
import { TokenService } from '../services/tokenService.js';
import { auth } from '../firebaseConfig.js';

// Fix baseURL
const baseURL =
  process.env.NODE_ENV === 'production' ? '/' : 'http://localhost:7071';

// Create a request queue for handling requests before Firebase is ready
let firebaseInitialized = false;
let pendingRequests = [];

// Function to process queued requests once Firebase is ready
const processPendingRequests = () => {
  logger.log(`Processing ${pendingRequests.length} pending requests`);

  // Process all pending requests
  pendingRequests.forEach(({ resolve, reject, config }) => {
    attachTokenAndProceed(config).then(resolve).catch(reject);
  });

  // Clear the queue
  pendingRequests = [];
};

// Wait for Firebase to initialize
const waitForFirebase = async (maxWaitMs = 5000) => {
  // Set a timeout to prevent infinite waiting
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => reject(new Error('Firebase init timeout')), maxWaitMs);
  });

  const checkAuth = new Promise((resolve) => {
    const check = () => {
      if (auth.currentUser !== undefined) {
        firebaseInitialized = true;
        resolve();
      } else {
        setTimeout(check, 100);
      }
    };
    check();
  });

  try {
    await Promise.race([checkAuth, timeoutPromise]);
    processPendingRequests();
  } catch (error) {
    logger.error('Firebase initialization timeout:', error);
    processPendingRequests(); // Process anyway to avoid blocking requests
  }
};

// Start the Firebase initialization check
waitForFirebase();

// Token attachment function
const attachTokenAndProceed = async (config) => {
  try {
    // Only try to get token if user is logged in
    if (auth.currentUser) {
      try {
        const token = await auth.currentUser.getIdToken(true); // Force refresh
        if (token) {
          // Set on this specific request
          config.headers.Authorization = `Bearer ${token}`;

          // Also update TokenService for consistency
          TokenService.setToken(token);

          logger.log(
            `Token attached to ${config.url} (length: ${token.length})`
          );
        } else {
          logger.warn(
            `No token available for ${config.url} despite having currentUser`
          );
        }
      } catch (tokenError) {
        // Log but don't block the request
        logger.error('Failed to get token for request:', tokenError);
      }
    } else {
      logger.info(`No auth.currentUser for request to ${config.url}`);
    }

    return config;
  } catch (error) {
    logger.error('Error in attachTokenAndProceed:', error);
    return config; // Never block the request, even on error
  }
};

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  // Add request validation
  validateStatus: (status) => {
    // Consider 401/403 as special cases for logging
    if (status === 401 || status === 403) {
      logger.warn(`Auth error: ${status} response received`);
    }
    return status >= 200 && status < 300; // Default success handler
  },
});

// Improved request interceptor - balancing simplicity with sophistication
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Skip token attachment for certain endpoints
      const isAuthOptional = ['/api/newsletter', '/api/VerifyPassword'].some(
        (path) => config.url.includes(path)
      );

      if (!auth.currentUser && !isAuthOptional) {
        // For APIs that need auth, but user isn't logged in
        console.warn(`Auth required for ${config.url} but user not logged in`);
        return Promise.reject(new Error('Authentication required'));
      }

      // Try to get token from TokenService first (for efficiency)
      let token = TokenService.getToken();
      const tokenExpired = TokenService.isTokenExpired();

      // If no token or token is expired/expiring soon, get a fresh one
      if ((!token || tokenExpired) && auth.currentUser) {
        try {
          // Only force refresh if necessary
          token = await auth.currentUser.getIdToken(tokenExpired);

          if (token) {
            // Update TokenService for future use
            TokenService.setToken(token);
          }
        } catch (err) {
          console.error('Token refresh error:', err);
          // Continue with request even if refresh fails
        }
      }

      // Attach token if available
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;

        // More discreet logging - log only in development or for debugging
        if (process.env.NODE_ENV === 'development') {
          console.log(`Auth header added to ${config.url.split('?')[0]}`);
        }
      }

      return config;
    } catch (error) {
      console.error('Request interceptor error:', error);
      return config;
    }
  },
  (error) => Promise.reject(error)
);

// Keep the TokenService listener for default headers
TokenService.onTokenUpdate((token) => {
  if (token) {
    // Set default header
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    logger.log('Default auth header updated from TokenService');

    // Log the token length to verify it's valid
    logger.log(`Token length: ${token.length}`);
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
    logger.log('Default auth header cleared');
  }
});

// Response interceptor for handling auth errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const statusCode = error.response?.status;
    const url = error.config?.url || 'unknown';

    logger.info(`Request to ${url} failed with status ${statusCode}`);

    // Handle 401 Unauthorized
    if (statusCode === 401) {
      // Try to refresh token if user is logged in
      if (auth.currentUser) {
        try {
          // Check if we've already tried to refresh
          if (error.config._retry) {
            logger.warn('Already attempted to refresh token, forcing sign out');
            TokenService.forceSignOut();
            return Promise.reject(error);
          }

          logger.info(`Attempting token refresh for 401 on ${url}`);
          error.config._retry = true;

          // Force refresh token - use a true boolean to guarantee fresh token
          const freshToken = await auth.currentUser.getIdToken(true);

          // Verify token length
          if (freshToken && freshToken.length > 100) {
            // Update request headers
            error.config.headers.Authorization = `Bearer ${freshToken}`;

            // Also update TokenService
            TokenService.setToken(freshToken);

            logger.info(
              `Token refreshed successfully, length: ${freshToken.length}`
            );

            // Retry the request with fresh token
            return axiosInstance(error.config);
          } else {
            logger.error(
              `Invalid token after refresh: ${freshToken?.length || 0} chars`
            );
            TokenService.forceSignOut();
          }
        } catch (tokenError) {
          logger.error('Token refresh failed during 401 handling:', tokenError);
          TokenService.forceSignOut();
        }
      } else {
        // User not logged in but accessing authenticated endpoint
        logger.warn(
          'Attempting to access authenticated endpoint while logged out'
        );
        window.location.href = '/Login?reason=auth_required';
      }
    }

    // Handle 400 Bad Request for invalid refresh tokens
    else if (statusCode === 400 && url.includes('token')) {
      logger.error('Possible invalid refresh token, forcing sign out');
      TokenService.forceSignOut();
    }

    return Promise.reject(error);
  }
);

// Add debugging and diagnostics capabilities
axiosInstance.diagnose = () => {
  return {
    hasDefaultAuthHeader:
      !!axiosInstance.defaults.headers.common['Authorization'],
    headerLength:
      axiosInstance.defaults.headers.common['Authorization']?.length || 0,
    isUserLoggedIn: !!auth.currentUser,
    pendingRequests: pendingRequests.length,
    firebaseInitialized,
  };
};

// Export our configured axios instance
export default axiosInstance;
