import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import profilepic from '../../../assets/profilepic.png';
import FollowButton from './FollowButton.js';
import './SocialCards.css';

const ProfileCardContainer = memo(
  ({
    user,
    currentUserId,
    showFollowButton = true,
    showEditButton = false,
    onEditProfile,
    onUpdateFollowing,
    className = '',

    directImage = false, // Re-add the directImage prop
  }) => {
    const { t } = useTranslation();

    // Validate required fields
    if (!user?.uid || !user?.name) {
      console.warn('Invalid user data:', user);
      return null;
    }

    const normalizedUser = {
      ...user,
      Company: user.Company || user.company || '',
      profilepicurl: user.profilepicurl || user.photoURL || profilepic,
      isFollowing: Boolean(user.isFollowing),
    };

    // If directImage is true and we have a URL, add cache-busting
    const imageUrl =
      directImage && normalizedUser.profilepicurl !== profilepic
        ? `${normalizedUser.profilepicurl}?t=${Date.now()}`
        : normalizedUser.profilepicurl;

    return (
      <div className={`profile-card ${className || ''}`}>
        <div className="profile-image-container">
          <img
            src={imageUrl}
            alt={`${normalizedUser.name}'s profile`}
            className="profile-card-image"
            onError={(e) => {
              e.target.src = profilepic;
            }}
          />
        </div>

        <div className="profile-details">
          <p className="profile-card-name">{normalizedUser.name}</p>
          {normalizedUser.Company && (
            <p className="profile-card-company">{normalizedUser.Company}</p>
          )}
          {normalizedUser.email && normalizedUser.email.length <= 50 && (
            <a
              href={`mailto:${normalizedUser.email}`}
              className="profile-card-email"
              title={t('Get in touch via email', { name: normalizedUser.name })}
            >
              {normalizedUser.email}
            </a>
          )}
        </div>
        <div className="profile-actions">
          {showFollowButton && normalizedUser.uid !== currentUserId && (
            <FollowButton
              isFollowing={normalizedUser.isFollowing}
              userId={normalizedUser.uid}
              currentUserId={currentUserId}
              onUpdateFollowing={onUpdateFollowing}
            />
          )}
          {showEditButton && normalizedUser.uid === currentUserId && (
            <button className="edit-profile-btn" onClick={onEditProfile}>
              {t('edit_profile')}
            </button>
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.user?.uid === nextProps.user?.uid &&
      prevProps.user?.isFollowing === nextProps.user?.isFollowing &&
      prevProps.currentUserId === nextProps.currentUserId
    );
  }
);

ProfileCardContainer.displayName = 'ProfileCardContainer';

ProfileCardContainer.propTypes = {
  user: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    Company: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    profilepicurl: PropTypes.string,
    photoURL: PropTypes.string,
    isFollowing: PropTypes.bool,
  }).isRequired,
  currentUserId: PropTypes.string.isRequired,
  showFollowButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  onEditProfile: PropTypes.func,
  onUpdateFollowing: PropTypes.func,
  className: PropTypes.string,
  forceImageRefresh: PropTypes.bool,
  directImage: PropTypes.bool,
};

export default ProfileCardContainer;
