import { auth } from '../firebaseConfig.js';
import logger from './logger.js';
import { TokenService } from '../services/tokenService.js';

/**
 * Diagnose and fix common authentication issues
 */
export const checkAuthHealth = async () => {
  try {
    // Check if user is logged in
    const user = auth.currentUser;
    if (!user) {
      logger.warn('Auth health check: No user logged in');
      return false;
    }

    // Check if we can get a token without force refresh
    try {
      const token = await user.getIdToken(false);
      if (!token) {
        logger.warn(
          'Auth health check: Could not get token without force refresh'
        );
        return false;
      }
    } catch (tokenError) {
      logger.error('Auth health check: Token retrieval failed', tokenError);
      // Try to force refresh the token
      try {
        await user.getIdToken(true);
      } catch (forceRefreshError) {
        logger.error(
          'Auth health check: Force refresh failed',
          forceRefreshError
        );
        TokenService.forceSignOut();
        return false;
      }
    }

    // Check token metadata
    try {
      const tokenResult = await user.getIdTokenResult();
      const tokenExpirationTime = new Date(
        tokenResult.expirationTime
      ).getTime();
      const currentTime = Date.now();

      // Token is about to expire (less than 5 minutes left)
      if (tokenExpirationTime - currentTime < 5 * 60 * 1000) {
        logger.warn('Auth health check: Token expiring soon, refreshing');
        await user.getIdToken(true);
      }
    } catch (metadataError) {
      logger.error(
        'Auth health check: Token metadata check failed',
        metadataError
      );
    }

    logger.log('Auth health check passed');
    return true;
  } catch (error) {
    logger.error('Auth health check failed with unexpected error', error);
    return false;
  }
};

// Run an auth health check on page load
export const setupAuthHealthMonitor = () => {
  // Check on page load
  setTimeout(() => checkAuthHealth(), 5000);

  // Also check periodically
  setInterval(() => checkAuthHealth(), 30 * 60 * 1000); // Every 30 minutes
};
