/**
 * Sikker logging utility som bare logger i utvikling
 * Blokkerer alle logger i produksjonsmiljø
 */

/**
 * Fjerner sensitiv informasjon fra objekter før logging
 * @param {Object} obj Objektet som skal renses
 * @returns {Object} Renset objekt
 */
const sanitizeForLogging = (obj) => {
  if (!obj || typeof obj !== 'object') return obj;

  const sensitiveFields = [
    'password',
    'token',
    'accessToken',
    'idToken',
    'secret',
  ];
  const cloned = { ...obj };

  for (const key of Object.keys(cloned)) {
    if (sensitiveFields.includes(key.toLowerCase())) {
      cloned[key] = '[REDACTED]';
    } else if (typeof cloned[key] === 'object') {
      cloned[key] = sanitizeForLogging(cloned[key]);
    }
  }

  return cloned;
};

const logger = {
  log: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      const sanitizedArgs = args.map((arg) =>
        typeof arg === 'object' ? sanitizeForLogging(arg) : arg
      );
      console.log(...sanitizedArgs);
    }
  },

  error: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      const sanitizedArgs = args.map((arg) =>
        typeof arg === 'object' ? sanitizeForLogging(arg) : arg
      );
      console.error(...sanitizedArgs);
    }

    // I produksjon kan vi valgfritt sende feil til en feilrapporteringstjeneste
    if (process.env.NODE_ENV === 'production' && typeof args[0] === 'object') {
      // Her kan du legge til kode for å rapportere feil til en tjeneste som Sentry
      // sendToErrorReporting(sanitizeForLogging(args[0]));
    }
  },

  warn: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      const sanitizedArgs = args.map((arg) =>
        typeof arg === 'object' ? sanitizeForLogging(arg) : arg
      );
      console.warn(...sanitizedArgs);
    }
  },

  info: (...args) => {
    if (process.env.NODE_ENV !== 'production') {
      const sanitizedArgs = args.map((arg) =>
        typeof arg === 'object' ? sanitizeForLogging(arg) : arg
      );
      console.info(...sanitizedArgs);
    }
  },
};

export default logger;
