import { useQuery, useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import logger from '../utils/logger.js';
import { useUser } from '../UserContext.js'; // Add this import

// Konstanter for query keys - sentralisert
export const USER_QUERY_KEYS = {
  data: (uid) => ['userData', uid],
  list: () => ['usersList'],
};

/**
 * Henter brukerdata fra API
 * @param {string} uid - Brukerens Firebase UID
 * @returns {Promise<Object>} - Brukerens profildata
 */
export const fetchUserData = async (uid) => {
  if (!uid || typeof uid !== 'string' || uid.length < 5) {
    throw new Error('Invalid user ID');
  }

  try {
    const response = await axiosInstance.get(
      `/api/userdata/${encodeURIComponent(uid)}`
    );
    return sanitizeUserData(response.data);
  } catch (error) {
    // Standardisert feilhåndtering
    if (error.response?.status === 404) {
      throw new Error('User not found');
    } else if (error.response?.status === 403) {
      throw new Error('You do not have permission to access this user data');
    } else if (error.response?.status === 401) {
      throw new Error('Authentication required');
    }

    // Re-throw med en mer brukervennlig melding
    throw new Error(
      `Failed to fetch user data: ${error.message || 'Unknown error'}`
    );
  }
};

/**
 * Saniterer og validerer brukerdata
 * @param {Object} userData - Rå brukerdata fra API
 * @returns {Object} - Sanitert brukerdata
 */
const sanitizeUserData = (userData) => {
  if (!userData || typeof userData !== 'object') {
    throw new Error('Invalid user data format');
  }

  // Whitelist tillatte felt
  const allowedFields = [
    'uid',
    'name',
    'email',
    'Company',
    'profilepicurl',
    'phone',
    'role',
    'subscription_status',
    'subscription_name',
    'subscription_end',
    'created',
    'lastLogin',
    'settings',
    // Legg til andre felt du trenger her
  ];

  const sanitized = {};

  allowedFields.forEach((field) => {
    if (userData[field] !== undefined) {
      // XSS-beskyttelse for string-verdier
      if (typeof userData[field] === 'string') {
        sanitized[field] = userData[field]
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;');
      } else {
        sanitized[field] = userData[field];
      }
    }
  });

  return sanitized;
};

/**
 * Oppdaterer brukerdata
 * @param {Object} payload - Data som skal oppdateres, inkludert uid
 * @returns {Promise<Object>} - Oppdatert brukerdata
 */
export const updateUserData = async (payload) => {
  if (!payload || !payload.uid) {
    throw new Error('User ID is required for profile updates');
  }

  const { uid, ...updateData } = payload;

  try {
    // Endre post til put for å matche API-konfigurasjon
    const response = await axiosInstance.put(
      `/api/updateprofile/${encodeURIComponent(uid)}`,
      updateData
    );

    return sanitizeUserData(response.data);
  } catch (error) {
    logger.error('Profile update error:', error);

    // Bedre feilhåndtering med mer spesifikke meldinger
    if (error.response?.status === 404) {
      throw new Error('Profile update failed: Endpoint not found');
    } else if (error.response?.status === 400) {
      throw new Error(
        `Profile update failed: ${error.response.data || 'Bad request'}`
      );
    } else if (error.response?.status === 401) {
      throw new Error('Profile update failed: Unauthorized');
    } else if (error.response?.status === 500) {
      throw new Error('Profile update failed: Server error');
    }

    throw new Error(
      `Failed to update user data: ${error.message || 'Unknown error'}`
    );
  }
};

/**
 * Hook for å hente brukerdata
 * @param {string} uid - Brukerens Firebase UID
 * @param {Object} options - React Query-opsjoner
 * @returns {Object} - React Query resultat
 */
export const useUserData = (uid, options = {}) => {
  const { user } = useUser(); // Get current auth state

  return useQuery(USER_QUERY_KEYS.data(uid), () => fetchUserData(uid), {
    enabled:
      !!uid &&
      !!user &&
      !!axiosInstance.defaults.headers.common['Authorization'],
    staleTime: 30 * 60 * 1000, // 30 minutes
    cacheTime: 60 * 60 * 1000, // 1 hour cache
    retry: 1,
    retryDelay: 3000,
    ...options,
  });
};

/**
 * Hook for å oppdatere brukerdata
 * @returns {Object} - React Query mutation resultat
 */
export const useUpdateUserData = () => {
  const queryClient = useQueryClient();

  return useMutation(updateUserData, {
    // Når mutasjonen er vellykket, oppdater cachen
    onSuccess: (data, variables) => {
      // Oppdater cachen med de nye dataene
      queryClient.setQueryData(USER_QUERY_KEYS.data(variables.uid), data);
    },
    // Optimistisk oppdatering
    onMutate: async (newData) => {
      // Avbryt pågående spørringer
      await queryClient.cancelQueries(USER_QUERY_KEYS.data(newData.uid));

      // Lagre tidligere data
      const previousData = queryClient.getQueryData(
        USER_QUERY_KEYS.data(newData.uid)
      );

      // Optimistisk oppdatering av cachen
      queryClient.setQueryData(USER_QUERY_KEYS.data(newData.uid), (old) => ({
        ...old,
        ...newData,
      }));

      // Returner kontekst med tidligere data
      return { previousData };
    },
    // Ved feil, rull tilbake til tidligere data
    onError: (err, newData, context) => {
      queryClient.setQueryData(
        USER_QUERY_KEYS.data(newData.uid),
        context.previousData
      );
    },
  });
};

/**
 * Helper for å sette optimistiske oppdateringer direkte
 * @param {Object} queryClient - React Query client
 * @param {string} uid - Brukerens Firebase UID
 * @param {Object} newData - Nye data
 */
export const setOptimisticUserData = (queryClient, uid, newData) => {
  queryClient.setQueryData(USER_QUERY_KEYS.data(uid), (old) => ({
    ...old,
    ...newData,
  }));
};

// Eksporter sanitizeUserData som ikke ble eksportert tidligere
export { sanitizeUserData };
