import React from 'react'; // Must be a default import

const CleanupTokens = () => {
  React.useEffect(() => {
    // Clean up authentication tokens
    try {
      // Clear Firebase auth tokens
      localStorage.removeItem(
        'firebase:authUser:' +
          process.env.REACT_APP_FIREBASE_API_KEY +
          ':[DEFAULT]'
      );
      sessionStorage.removeItem(
        'firebase:authUser:' +
          process.env.REACT_APP_FIREBASE_API_KEY +
          ':[DEFAULT]'
      );

      console.log('Tokens cleared successfully');
    } catch (error) {
      console.error('Failed to clean up tokens:', error);
    }
  }, []);

  // Return some JSX
  return (
    <div className="auth-cleanup">
      <h2>Cleaning up authentication tokens...</h2>
      <p>Please wait.</p>
    </div>
  );
};

export default CleanupTokens;
