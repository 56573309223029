import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTasks } from '../../hooks/useTasks.js';
import { useQueryClient, useMutation } from 'react-query';
import axiosInstance from '../../config/axios.js';
import { toast, ToastContainer } from 'react-toastify';
import TaskAssignee from './TaskAssignee.js';
import { QueryKeys } from '../../constants/queryKeys.js';
import Gantt from 'frappe-gantt';
import './MobileProjects.css';
import LoadingPage from '../Spinner/LoadingPage.js';

// Icons
import {
  IoAdd,
  IoClose,
  IoTrashOutline,
  IoTimerOutline,
} from 'react-icons/io5';
import { MdUndo } from 'react-icons/md';
import { FaSave } from 'react-icons/fa';
import { HiOutlineZoomIn, HiOutlineZoomOut } from 'react-icons/hi';
import 'react-toastify/dist/ReactToastify.css';

// API helper functions
const updateTasks = async (tasks) => {
  return await axiosInstance.put(`/api/puttasks`, { tasks });
};

const deleteTask = async (taskId) => {
  return await axiosInstance.delete(`/api/deletetask/${taskId}`);
};

const postTasks = async (tasks) => {
  return await axiosInstance.post(`/api/posttasks`, { tasks });
};

// Format dependency helpers
const stringifyDependencies = (dependencies) => {
  if (!dependencies) return '';
  if (Array.isArray(dependencies)) return dependencies.join(',');
  if (typeof dependencies === 'string') return dependencies;
  return '';
};

const formatDependencies = {
  toString: (dep) => {
    if (!dep && dep !== 0) return '';
    if (Array.isArray(dep)) {
      return dep.length > 0 ? dep[0].toString() : '';
    }
    if (typeof dep === 'number') return dep.toString();
    return dep.toString();
  },
  toNumber: (dep) => {
    if (!dep && dep !== 0) return null;
    if (Array.isArray(dep)) {
      return dep.length > 0 ? parseInt(dep[0]) : null;
    }
    if (typeof dep === 'number') return dep;
    const parsed = parseInt(dep);
    return isNaN(parsed) ? null : parsed;
  },
};

const MobileProjects = () => {
  // State management
  const { data: tasks = [], isLoading } = useTasks();
  const [localTaskValues, setLocalTaskValues] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [temporaryTasks, setTemporaryTasks] = useState([]);
  const activeFilter = 'all';
  const [selectedTask, setSelectedTask] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [viewMode, setViewMode] = useState('Week');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const ganttRef = useRef(null);
  const ganttInstance = useRef(null);
  const queryClient = useQueryClient();

  // New state variables for onboarding
  const [showZoomOnboarding, setShowZoomOnboarding] = useState(true);
  const [zoomOnboardingComplete, setZoomOnboardingComplete] = useState(false);

  // Set up mutation for task updates
  const mutation = useMutation(updateTasks, {
    onMutate: async (updatedTasks) => {
      await queryClient.cancelQueries('tasks');
      const previousTasks = queryClient.getQueryData('tasks');
      queryClient.setQueryData('tasks', (old) =>
        old.map(
          (task) =>
            updatedTasks.find((updatedTask) => updatedTask.id === task.id) ||
            task
        )
      );
      return { previousTasks };
    },
    onError: (err, updatedTasks, context) => {
      queryClient.setQueryData('tasks', context.previousTasks);
    },
    onSettled: () => {
      queryClient.invalidateQueries('tasks');
    },
  });

  // Initialize tasks from API
  useEffect(() => {
    if (!isLoading && tasks?.length > 0) {
      const formattedTasks = tasks.map((task) => ({
        ...task,
        dependencies: formatDependencies.toNumber(task.dependencies),
      }));
      setTemporaryTasks(formattedTasks);
      setOriginalTasks(JSON.parse(JSON.stringify(formattedTasks)));
    }
  }, [tasks, isLoading]);

  // Function to handle onboarding completion - defined first to avoid dependency issues
  const handleOnboardingComplete = useCallback(() => {
    setShowZoomOnboarding(false);
    setTimeout(() => {
      setZoomOnboardingComplete(true);
      localStorage.setItem('hasSeenZoomOnboarding', 'true');
    }, 300); // Short delay for animation
  }, []);

  // Check if the user has seen the onboarding
  useEffect(() => {
    const hasSeenOnboarding = localStorage.getItem('hasSeenZoomOnboarding');
    if (hasSeenOnboarding) {
      setShowZoomOnboarding(false);
      setZoomOnboardingComplete(true);
    }

    // Auto-hide onboarding after 15 seconds if no interaction
    let timeout;
    if (showZoomOnboarding) {
      timeout = setTimeout(() => {
        handleOnboardingComplete();
      }, 15000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [showZoomOnboarding, handleOnboardingComplete]); // Fixed syntax error here

  // Enhanced zoom functions to mark onboarding as complete
  const zoomIn = useCallback(() => {
    if (showZoomOnboarding) {
      handleOnboardingComplete();
    }

    if (viewMode === 'Year') setViewMode('Month');
    else if (viewMode === 'Month') setViewMode('Week');
    else if (viewMode === 'Week') setViewMode('Day');
  }, [viewMode, showZoomOnboarding, handleOnboardingComplete]); // Added missing dependency

  const zoomOut = useCallback(() => {
    if (showZoomOnboarding) {
      handleOnboardingComplete();
    }

    if (viewMode === 'Day') setViewMode('Week');
    else if (viewMode === 'Week') setViewMode('Month');
    else if (viewMode === 'Month') setViewMode('Year');
  }, [viewMode, showZoomOnboarding, handleOnboardingComplete]); // Added missing dependency

  // Update local tasks
  const updateLocalTasks = useCallback(
    (updatedTask) => {
      setTaskHistory((prevHistory) => [
        ...prevHistory,
        JSON.parse(JSON.stringify(temporaryTasks)),
      ]);

      setTemporaryTasks((oldTasks) =>
        oldTasks.map((task) =>
          task.id === updatedTask.id ? updatedTask : task
        )
      );

      queryClient.setQueryData('tasks', (old) =>
        old.map((task) => (task.id === updatedTask.id ? updatedTask : task))
      );
    },
    [temporaryTasks, queryClient]
  );

  // Render Gantt chart
  const renderGantt = useCallback(() => {
    if (!ganttRef.current || temporaryTasks.length === 0) return;

    ganttRef.current.innerHTML = '';

    // Convert tasks for Gantt, ensuring dependencies are strings
    const tasksForGantt = temporaryTasks
      .filter((task) => {
        if (activeFilter === 'all') return true;
        if (activeFilter === 'active')
          return parseInt(task.progress || 0) < 100;
        if (activeFilter === 'completed')
          return parseInt(task.progress || 0) === 100;
        return true;
      })
      .map((task) => {
        const dependencies =
          task.dependencies !== null && task.dependencies !== undefined
            ? task.dependencies.toString()
            : '';

        return {
          ...task,
          dependencies,
        };
      });

    ganttInstance.current = new Gantt(ganttRef.current, tasksForGantt, {
      on_click: (task) => {
        // Use isScrolling to prevent task selection during scrolling
        if (window.isGanttScrolling) {
          window.isGanttScrolling = false;
          return;
        }

        // Find the matching task from our state to ensure we have full data
        const fullTask = temporaryTasks.find((t) => t.id === task.id);
        if (fullTask) {
          setSelectedTask(fullTask);
          setShowModal(true);
        }
      },
      on_date_change: (task, start, end) => {
        const updatedTask = {
          ...task,
          start,
          end,
          dependencies: formatDependencies.toNumber(task.dependencies),
          isModified: true,
        };
        updateLocalTasks(updatedTask);
      },
      on_progress_change: (task, progress) => {
        const updatedTask = {
          ...task,
          progress,
          dependencies: formatDependencies.toNumber(task.dependencies),
          isModified: true,
        };
        updateLocalTasks(updatedTask);
      },
      custom_popup_html: (task) => {
        const progressClass =
          parseInt(task.progress) >= 75
            ? 'high-progress'
            : parseInt(task.progress) >= 50
              ? 'medium-progress'
              : parseInt(task.progress) >= 25
                ? 'low-progress'
                : 'no-progress';

        return `
          <div class="mobile-popup-wrapper">
            <div class="mobile-popup-progress ${progressClass}" style="width: ${task.progress}%"></div>
            <div class="mobile-popup-title">${task.name}</div>
            <div class="mobile-popup-dates">
              <span>${formatDate(task.start)}</span>
              <span>→</span>
              <span>${formatDate(task.end)}</span>
            </div>
            <div class="mobile-popup-footer">
              <span class="mobile-popup-progress-text">${task.progress}% complete</span>
              <span class="mobile-popup-tap-hint">Tap to edit</span>
            </div>
          </div>
        `;
      },
      bar_height: 38,
      bar_corner_radius: 6,
      padding: 24,
      view_mode: viewMode,
      date_format: 'MMM D',
      popup_trigger: 'click',
      language: 'en',
    });

    setTimeout(() => {
      if (ganttInstance.current && tasksForGantt.length > 0) {
        // Instead of using a method that doesn't exist:
        // ganttInstance.current.scroll_to(today);

        // Find today's date column and scroll to it
        const today = new Date();
        const dateStr = today.toISOString().split('T')[0]; // YYYY-MM-DD
        const todayElement =
          ganttRef.current.querySelector(`.date-${dateStr}`) ||
          ganttRef.current.querySelector('.today');

        if (todayElement) {
          // Get the parent scrollable container
          const scrollContainer =
            ganttRef.current.querySelector('.gantt-container');
          if (scrollContainer) {
            // Calculate position to center today in view
            const containerWidth = scrollContainer.clientWidth;
            const todayPos = todayElement.offsetLeft;
            const scrollTo = Math.max(0, todayPos - containerWidth / 2);

            scrollContainer.scrollLeft = scrollTo;
          }
        }
      }
    }, 300); // Increased timeout to ensure DOM is ready
  }, [viewMode, temporaryTasks, activeFilter, updateLocalTasks]);

  useEffect(() => {
    if (temporaryTasks.length > 0) {
      renderGantt();
    }
  }, [renderGantt, temporaryTasks]);

  // Add this inside the existing useEffect for touch events, modifying to support mouse events as well
  useEffect(() => {
    if (!ganttRef.current) return;

    const ganttElement = ganttRef.current;
    let touchStartX, touchStartY;
    let mouseStartX, mouseStartY;
    let lastScrollLeft, lastScrollTop;
    let isMouseDown = false;

    // Get the scrollable container
    const getScrollContainer = () => {
      return (
        ganttElement.querySelector('.gantt-container') ||
        ganttElement.querySelector('.grid-container') ||
        ganttElement
      );
    };

    // TOUCH EVENTS (existing code)
    const handleTouchStart = (e) => {
      const container = getScrollContainer();
      if (!container) return;

      touchStartX = e.touches[0].clientX;
      touchStartY = e.touches[0].clientY;
      lastScrollLeft = container.scrollLeft;
      lastScrollTop = container.scrollTop;
    };

    const handleTouchMove = (e) => {
      const container = getScrollContainer();
      if (!container || !touchStartX || !touchStartY) return;

      // Calculate movement
      const touchX = e.touches[0].clientX;
      const touchY = e.touches[0].clientY;
      const deltaX = touchStartX - touchX;
      const deltaY = touchStartY - touchY;

      // Detect scroll direction and apply it
      if (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5) {
        window.isGanttScrolling = true; // Use a window property to track across functions
        container.scrollLeft = lastScrollLeft + deltaX;
        container.scrollTop = lastScrollTop + deltaY;

        // Prevent default to avoid page scrolling while manipulating chart
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          e.preventDefault();
        }
      }
    };

    const handleTouchEnd = () => {
      touchStartX = null;
      touchStartY = null;
    };

    // MOUSE EVENTS (new code for mouse navigation)
    const handleMouseDown = (e) => {
      // Support both left button (0) and middle button (1)
      // Right button (2) is typically used for context menus, so we'll skip that
      if (e.button > 1) return;

      // For left button clicks, we need to check if we're clicking on a task bar
      // If clicking on a task bar, let the normal click handler work
      if (e.button === 0) {
        // Check if we're clicking on a task bar
        const target = e.target;
        if (target.closest('.bar') || target.closest('.handle')) {
          return; // Allow normal selection/resize to work
        }
      }

      const container = getScrollContainer();
      if (!container) return;

      e.preventDefault(); // Prevent text selection during drag

      isMouseDown = true;
      mouseStartX = e.clientX;
      mouseStartY = e.clientY;
      lastScrollLeft = container.scrollLeft;
      lastScrollTop = container.scrollTop;

      // Change cursor to indicate grabbing
      document.body.style.cursor = 'grabbing';
      ganttElement.style.userSelect = 'none';
    };

    const handleMouseMove = (e) => {
      if (!isMouseDown) return;

      const container = getScrollContainer();
      if (!container) return;

      // Calculate movement
      const deltaX = mouseStartX - e.clientX;
      const deltaY = mouseStartY - e.clientY;

      // Apply scrolling
      container.scrollLeft = lastScrollLeft + deltaX;
      container.scrollTop = lastScrollTop + deltaY;

      window.isGanttScrolling = true;
    };

    const handleMouseUp = () => {
      isMouseDown = false;
      mouseStartX = null;
      mouseStartY = null;

      // Reset cursor
      document.body.style.cursor = '';
      ganttElement.style.userSelect = '';

      // We'll let the click handler reset the window.isGanttScrolling flag after a short delay
      setTimeout(() => {
        window.isGanttScrolling = false;
      }, 50);
    };

    // Mouse wheel horizontal scrolling enhancement
    let wheelTimer = null;
    const handleWheel = (e) => {
      // If the horizontal scroll is being used natively, let the browser handle it
      if (e.deltaX !== 0) return;

      // Get scrollable container
      const container = getScrollContainer();
      if (!container) return;

      // If shift key is pressed, use vertical wheel for horizontal scrolling (standard behavior)
      if (e.shiftKey) {
        e.preventDefault();
        container.scrollLeft += e.deltaY;
        return;
      }

      // Otherwise use wheel for zooming (without modifier keys):
      // Scroll up (negative deltaY) = zoom in
      // Scroll down (positive deltaY) = zoom out
      e.preventDefault();

      // Add a small delay to prevent too many zoom operations at once
      if (wheelTimer) clearTimeout(wheelTimer);

      wheelTimer = setTimeout(() => {
        // Determine zoom direction based on scroll direction
        if (e.deltaY < 0) {
          zoomIn(); // Scroll up = zoom in
        } else {
          zoomOut(); // Scroll down = zoom out
        }
      }, 50);
    };

    // Add touch event listeners
    ganttElement.addEventListener('touchstart', handleTouchStart, {
      passive: true,
    });
    ganttElement.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    });
    ganttElement.addEventListener('touchend', handleTouchEnd);

    // Add mouse event listeners
    ganttElement.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mouseleave', handleMouseUp);
    ganttElement.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      // Remove touch event listeners
      ganttElement.removeEventListener('touchstart', handleTouchStart);
      ganttElement.removeEventListener('touchmove', handleTouchMove);
      ganttElement.removeEventListener('touchend', handleTouchEnd);

      // Remove mouse event listeners
      ganttElement.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mouseleave', handleMouseUp);
      ganttElement.removeEventListener('wheel', handleWheel);

      if (wheelTimer) {
        clearTimeout(wheelTimer);
      }
    };
  }, [zoomIn, zoomOut]);

  // Add this to your existing useEffect for event handlers
  useEffect(() => {
    // Prevent pinch-zoom gestures
    const preventZoom = (e) => {
      // If there are 2 or more touches (pinch gesture)
      if (e.touches && e.touches.length > 1) {
        e.preventDefault();
      }
    };

    // Add listener to prevent zoom
    document.addEventListener('touchmove', preventZoom, { passive: false });

    // Prevent double-tap to zoom
    let lastTapTime = 0;
    const preventDoubleTapZoom = (e) => {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTapTime;
      if (tapLength < 500 && tapLength > 0) {
        e.preventDefault();
      }
      lastTapTime = currentTime;
    };

    document.addEventListener('touchend', preventDoubleTapZoom, {
      passive: false,
    });

    return () => {
      // Clean up
      document.removeEventListener('touchmove', preventZoom);
      document.removeEventListener('touchend', preventDoubleTapZoom);
    };
  }, []);

  // Legg til en separat useEffect for å initialisere musehjul-zoomingen riktig
  useEffect(() => {
    // Nullstill scrolling-flagger
    window.isGanttScrolling = false;

    // Sjekk at gantt er rendret
    if (ganttRef.current && temporaryTasks.length > 0) {
      const ganttElement = ganttRef.current;

      // Definerer hjul-handler
      const handleInitialWheel = (e) => {
        // Skip hvis det er horisontal scrolling
        if (e.deltaX !== 0) return;

        // Hent scrollable container
        const container =
          ganttElement.querySelector('.gantt-container') ||
          ganttElement.querySelector('.grid-container') ||
          ganttElement;

        if (!container) return;

        // Hvis shift brukes, scroll horisontalt
        if (e.shiftKey) {
          e.preventDefault();
          container.scrollLeft += e.deltaY;
          return;
        }

        // Bruk hjul for zoom
        e.preventDefault();

        // Zoom basert på scrollretning
        if (e.deltaY < 0) {
          zoomIn(); // Scroll opp = zoom inn
        } else {
          zoomOut(); // Scroll ned = zoom ut
        }
      };

      // Legg til event listener direkte
      ganttElement.addEventListener('wheel', handleInitialWheel, {
        passive: false,
      });

      return () => {
        ganttElement.removeEventListener('wheel', handleInitialWheel);
      };
    }
  }, [temporaryTasks, zoomIn, zoomOut]); // Fjernet ganttRef.current fra dependency array

  // Helper functions
  const formatDate = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    });
  };

  const getDaysRemaining = (endDate) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
    const diffTime = end - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  };

  // Task handling functions
  const handleAddTask = () => {
    const today = new Date();
    const newTask = {
      id: `temp-${new Date().getTime()}`,
      name: 'New Task',
      start: today,
      end: new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000),
      progress: 0,
      dependencies: [],
      custom_class: '',
      assignee: '',
    };

    setTaskHistory((prev) => [...prev, [...temporaryTasks]]);
    setTemporaryTasks((prev) => [...prev, newTask]);
    setSelectedTask(newTask);
    setShowModal(true);
  };

  // Zoom controls
  const handleSave = async () => {
    setIsSaving(true);
    try {
      const tasksToSave = temporaryTasks.map((task) => ({
        ...task,
        id: task.id || `temp-${new Date().getTime()}`,
        dependencies: stringifyDependencies(task.dependencies),
      }));

      // Handle new tasks
      const newTasks = tasksToSave.filter((task) =>
        task.id.startsWith('temp-')
      );
      if (newTasks.length > 0) {
        await postTasks(newTasks);
      }

      // Handle existing tasks
      const existingTasks = tasksToSave.filter(
        (task) => !task.id.startsWith('temp-')
      );
      const tasksToUpdate = existingTasks.filter((task) => {
        const originalTask = originalTasks.find((t) => t.id === task.id);
        return JSON.stringify(task) !== JSON.stringify(originalTask);
      });

      if (tasksToUpdate.length > 0) {
        await mutation.mutateAsync(tasksToUpdate);
      }

      await queryClient.invalidateQueries('tasks');
      setTaskHistory([]);
      setLocalTaskValues({});
      toast.success('Changes saved successfully!');
    } catch (error) {
      toast.error('Failed to save changes');
      console.error('Save error:', error);
    } finally {
      setIsSaving(false);
    }
  };

  // Undo changes
  const handleUndo = useCallback(() => {
    if (taskHistory.length > 0) {
      const previousTasks = taskHistory[taskHistory.length - 1];
      setTaskHistory((prev) => prev.slice(0, -1));
      setTemporaryTasks(previousTasks);
      queryClient.setQueryData('tasks', previousTasks);
    } else {
      setTemporaryTasks(JSON.parse(JSON.stringify(originalTasks)));
      queryClient.setQueryData(
        'tasks',
        JSON.parse(JSON.stringify(originalTasks))
      );
    }
  }, [taskHistory, originalTasks, queryClient]);

  // Input handling for modal
  const handleInputChange = (field, value) => {
    if (!selectedTask) return;

    setLocalTaskValues((prev) => ({
      ...prev,
      [selectedTask.id]: {
        ...(prev[selectedTask.id] || {}),
        [field]: value,
      },
    }));
  };

  // Save task changes from modal
  const saveTaskChanges = () => {
    if (!selectedTask) return;

    const localValues = localTaskValues[selectedTask.id];

    if (localValues) {
      setTaskHistory((prev) => [...prev, [...temporaryTasks]]);

      const dependencies =
        localValues.dependencies !== undefined
          ? formatDependencies.toNumber(localValues.dependencies)
          : selectedTask.dependencies;

      const newTask = {
        ...selectedTask,
        ...localValues,
        dependencies,
        isModified: true,
      };

      setTemporaryTasks((prevTasks) =>
        prevTasks.map((task) => (task.id === selectedTask.id ? newTask : task))
      );

      setLocalTaskValues((prev) => ({
        ...prev,
        [selectedTask.id]: {},
      }));
    }

    setShowModal(false);
    setSelectedTask(null);
  };

  // Delete task
  const handleDelete = () => {
    if (selectedTask) {
      setShowDeleteConfirm(true);
    }
  };

  const confirmDelete = async () => {
    try {
      if (!selectedTask.id.startsWith('temp-')) {
        await deleteTask(selectedTask.id);
      }
      setTaskHistory((prev) => [...prev, [...temporaryTasks]]);
      const updatedTasks = temporaryTasks.filter(
        (task) => task.id !== selectedTask.id
      );
      setTemporaryTasks(updatedTasks);
      queryClient.setQueryData('tasks', updatedTasks);
      setShowDeleteConfirm(false);
      setShowModal(false);
      setSelectedTask(null);
      toast.success('Task deleted successfully');
    } catch (error) {
      toast.error('Failed to delete task');
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirm(false);
  };

  // Assignee handling
  const handleAssigneeSelect = (user) => {
    if (!selectedTask) return;

    setTaskHistory((prev) => [...prev, [...temporaryTasks]]);

    const updatedValues = {
      assignee: user.uid,
      assigneeName: user.name,
      assigneePhoto: user.profilepicurl,
      assigneeCompany: user.Company,
    };

    setLocalTaskValues((prev) => ({
      ...prev,
      [selectedTask.id]: {
        ...(prev[selectedTask.id] || {}),
        ...updatedValues,
      },
    }));
  };

  const renderTaskAssignee = (task) => {
    if (!task)
      return {
        name: 'Not assigned',
        uid: null,
        profilepicurl: null,
        Company: null,
      };

    const localValue = localTaskValues[task.id];
    const currentTask = localValue || task;

    if (currentTask.assigneeName && currentTask.assigneePhoto) {
      return {
        name: currentTask.assigneeName || 'Not assigned',
        uid: currentTask.assignee,
        profilepicurl: currentTask.assigneePhoto,
        Company: currentTask.assigneeCompany,
      };
    }

    const allUsers = queryClient.getQueryData(QueryKeys.users.all) || [];
    const assigneeUser = allUsers.find((u) => u.uid === currentTask.assignee);

    if (assigneeUser) {
      return {
        name: assigneeUser.name,
        uid: assigneeUser.uid,
        profilepicurl: assigneeUser.profilepicurl,
        Company: assigneeUser.Company,
      };
    }

    return {
      name: 'Not assigned',
      uid: null,
      profilepicurl: null,
      Company: null,
    };
  };

  // Rendering logic
  if (isLoading) {
    return <LoadingPage />;
  }

  if (!temporaryTasks || temporaryTasks.length === 0) {
    return (
      <div className="mobile-empty-state">
        <div className="mobile-empty-icon">📋</div>
        <h3>No tasks yet</h3>
        <p>Create your first task to get started</p>
        <button className="mobile-add-button" onClick={handleAddTask}>
          <IoAdd size={24} /> Add New Task
        </button>
      </div>
    );
  }

  // Get task for modal (either from local values or temporaryTasks)
  const currentTask = selectedTask
    ? localTaskValues[selectedTask.id]
      ? { ...selectedTask, ...localTaskValues[selectedTask.id] }
      : selectedTask
    : null;

  // If we have a task selected for editing, create the start/end dates
  const taskStartDate =
    currentTask?.start instanceof Date
      ? currentTask.start.toISOString().split('T')[0]
      : currentTask
        ? new Date(currentTask.start).toISOString().split('T')[0]
        : '';

  const taskEndDate =
    currentTask?.end instanceof Date
      ? currentTask.end.toISOString().split('T')[0]
      : currentTask
        ? new Date(currentTask.end).toISOString().split('T')[0]
        : '';

  const progressValue = parseInt(currentTask?.progress || 0);
  const daysRemaining = currentTask ? getDaysRemaining(currentTask.end) : 0;
  const duration = currentTask
    ? calculateDuration(currentTask.start, currentTask.end)
    : 0;
  const isOverdue = daysRemaining < 0 && progressValue < 100;

  // Update the component return statement to use the centered header layout
  return (
    <div className="mobile-gantt-projects">
      <div className="mobile-header">
        <div className="mobile-actions">
          <button
            className={`mobile-action-button ${taskHistory.length > 0 ? 'has-changes' : ''}`}
            onClick={handleSave}
            disabled={isSaving}
          >
            <FaSave size={20} />
          </button>
          <button
            className="mobile-action-button"
            onClick={handleUndo}
            disabled={taskHistory.length === 0}
          >
            <MdUndo size={20} />
            {taskHistory.length > 0 && (
              <span className="mobile-badge">{taskHistory.length}</span>
            )}
          </button>
          <button
            className="mobile-add-button"
            onClick={handleAddTask}
            disabled={isSaving}
          >
            <IoAdd size={20} /> New Task
          </button>
        </div>
      </div>

      {/* Gantt Chart */}
      <div className="mobile-gantt-container">
        <div ref={ganttRef} className="gantt-chart"></div>

        {/* Onboarding zoom controls (centered) */}
        {showZoomOnboarding && (
          <div className="mobile-zoom-onboarding">
            <div className="zoom-onboarding-text">
              Use these buttons to change the timeline zoom
            </div>
            <div className="zoom-onboarding-controls">
              <button
                onClick={zoomIn}
                className="gantt-control-btn zoom-onboarding-btn"
                title="Zoom in"
              >
                <HiOutlineZoomIn size={32} />
              </button>
              <button
                onClick={zoomOut}
                className="gantt-control-btn zoom-onboarding-btn blink"
                title="Zoom out"
              >
                <HiOutlineZoomOut size={32} />
                <span className="try-me-text">Try Me</span>
              </button>
            </div>
          </div>
        )}

        {/* Regular zoom controls (only show when onboarding is complete) */}
        {zoomOnboardingComplete && (
          <div className="mobile-gantt-controls">
            <button
              onClick={zoomIn}
              className="gantt-control-btn"
              title="Zoom in"
            >
              <HiOutlineZoomIn size={24} />
            </button>
            <button
              onClick={zoomOut}
              className="gantt-control-btn"
              title="Zoom out"
            >
              <HiOutlineZoomOut size={24} />
            </button>
          </div>
        )}
      </div>

      {/* Rest of your component remains unchanged */}
      {/* Task edit modal */}
      {showModal && currentTask && (
        <div
          className="mobile-modal-backdrop"
          onClick={() => setShowModal(false)}
        >
          <div
            className={`mobile-task-modal ${isOverdue ? 'overdue' : ''}`}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Progress bar at top of modal */}
            <div className="mobile-modal-progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${progressValue}%` }}
              ></div>
            </div>

            {/* Modal header with title */}
            <div className="mobile-modal-header">
              <button
                className="mobile-modal-close"
                onClick={() => setShowModal(false)}
              >
                <IoClose size={24} />
              </button>
              <input
                type="text"
                value={
                  localTaskValues[currentTask.id]?.name !== undefined
                    ? localTaskValues[currentTask.id]?.name
                    : currentTask.name
                }
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="mobile-modal-title"
              />
              <div className="mobile-modal-status">
                <span
                  className={`days-remaining ${isOverdue ? 'overdue' : ''}`}
                >
                  <IoTimerOutline className="icon" />
                  {daysRemaining > 0
                    ? `${daysRemaining} days left`
                    : daysRemaining === 0
                      ? 'Due today'
                      : `${Math.abs(daysRemaining)} days overdue`}
                </span>
              </div>
            </div>

            {/* Modal body with form fields */}
            <div className="mobile-modal-body">
              <div className="mobile-form-group">
                <label className="mobile-form-label">Assignee</label>
                <div className="mobile-form-field">
                  <TaskAssignee
                    currentAssignee={renderTaskAssignee(currentTask)}
                    context="modal"
                    onAssigneeSelect={handleAssigneeSelect}
                  />
                </div>
              </div>

              <div className="mobile-form-row">
                <div className="mobile-form-group">
                  <label className="mobile-form-label">Start Date</label>
                  <input
                    type="date"
                    value={taskStartDate}
                    onChange={(e) =>
                      handleInputChange('start', new Date(e.target.value))
                    }
                    className="mobile-form-input"
                  />
                </div>

                <div className="mobile-form-group">
                  <label className="mobile-form-label">End Date</label>
                  <input
                    type="date"
                    value={taskEndDate}
                    onChange={(e) =>
                      handleInputChange('end', new Date(e.target.value))
                    }
                    className="mobile-form-input"
                  />
                </div>
              </div>

              <div className="mobile-form-row">
                <div className="mobile-form-group">
                  <label className="mobile-form-label">Duration</label>
                  <div className="mobile-form-value">{duration} days</div>
                </div>

                <div className="mobile-form-group">
                  <label className="mobile-form-label">Progress</label>
                  <select
                    value={
                      localTaskValues[currentTask.id]?.progress !== undefined
                        ? localTaskValues[currentTask.id]?.progress
                        : currentTask.progress || 0
                    }
                    onChange={(e) =>
                      handleInputChange('progress', e.target.value)
                    }
                    className="mobile-form-select"
                  >
                    <option value="0">0%</option>
                    <option value="25">25%</option>
                    <option value="50">50%</option>
                    <option value="75">75%</option>
                    <option value="100">100%</option>
                  </select>
                </div>
              </div>

              <div className="mobile-form-group">
                <label className="mobile-form-label">Dependencies</label>
                <select
                  value={
                    localTaskValues[currentTask.id]?.dependencies !== undefined
                      ? formatDependencies.toString(
                          localTaskValues[currentTask.id]?.dependencies
                        )
                      : formatDependencies.toString(currentTask.dependencies)
                  }
                  onChange={(e) =>
                    handleInputChange('dependencies', e.target.value)
                  }
                  className="mobile-form-select"
                >
                  <option value="">No dependencies</option>
                  {temporaryTasks
                    .filter((t) => t.id !== currentTask.id)
                    .map((t) => (
                      <option key={t.id} value={t.id}>
                        {t.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mobile-form-group">
                <label className="mobile-form-label">Classification</label>
                <input
                  type="text"
                  value={
                    localTaskValues[currentTask.id]?.custom_class !== undefined
                      ? localTaskValues[currentTask.id]?.custom_class
                      : currentTask.custom_class || ''
                  }
                  onChange={(e) =>
                    handleInputChange('custom_class', e.target.value)
                  }
                  placeholder="Add classification"
                  className="mobile-form-input"
                />
              </div>

              {/* Modal actions */}
              <div className="mobile-modal-actions">
                {showDeleteConfirm ? (
                  <div className="mobile-delete-confirm">
                    <p>Delete this task?</p>
                    <div className="mobile-delete-actions">
                      <button
                        className="mobile-confirm-delete"
                        onClick={confirmDelete}
                      >
                        Yes, delete
                      </button>
                      <button
                        className="mobile-cancel-delete"
                        onClick={cancelDelete}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <>
                    <button
                      className="mobile-save-button"
                      onClick={saveTaskChanges}
                    >
                      Save Changes
                    </button>
                    <button
                      className="mobile-delete-button"
                      onClick={handleDelete}
                    >
                      <IoTrashOutline size={18} />
                      Delete Task
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer position="bottom-center" />
    </div>
  );
};

export default MobileProjects;
