import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useUpdateUserData } from '../../../hooks/useUserProfile.js'; // Updated import for useUserProfile.js
import { useProfilePicture } from '../../../hooks/useProfilePicture.js';
import { useUser } from '../../../UserContext.js';
import profilepic from '../../../assets/profilepic.png'; // Import default profile pic
import './ProfileModal.css';

const ProfileModal = ({ onClose, profileData }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [formData, setFormData] = useState({
    name: '',
    Company: '',
    profileImage: null,
  });
  const queryClient = useQueryClient();
  // Use the default profile pic if profilepicurl is not available
  const [profilePicUrl, setProfilePicUrl] = useState(
    profileData?.profilepicurl || user?.photoURL || profilepic
  );
  const updateProfile = useUpdateUserData(); // Use the new central data module for updates
  const uploadProfilePicture = useProfilePicture();

  useEffect(() => {
    if (profileData) {
      setFormData({
        name: profileData.name || user?.displayName || '',
        Company: profileData.Company || '',
        profileImage: null,
      });
      // Ensure we have a fallback for the profile image
      setProfilePicUrl(
        profileData.profilepicurl || user?.photoURL || profilepic
      );
    }
  }, [profileData, user]);

  const handleChange = (e) => {
    const { name, files, value } = e.target;

    if (files && files[0]) {
      const file = files[0];
      setFormData({ ...formData, profileImage: file });

      // Preview image
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfilePicUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleImageUpload = async () => {
    if (!formData.profileImage) {
      return null;
    }

    try {
      const cachedUserId =
        queryClient.getQueryData('currentUserId') || user?.uid;

      if (!cachedUserId) {
        throw new Error('User ID not found');
      }

      console.log('Starting profile picture upload for user:', cachedUserId);

      const cdnUrl = await uploadProfilePicture.mutateAsync({
        userId: cachedUserId,
        file: formData.profileImage,
      });

      return cdnUrl;
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      toast.error(t('Failed to upload profile picture'));
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let profileImageUrl = profilePicUrl;

      if (formData.profileImage) {
        console.log('Uploading profile image first...');
        profileImageUrl = await handleImageUpload();
        if (!profileImageUrl) {
          console.warn('Image upload failed or was cancelled');
          return; // Stop if image upload failed
        }
      }

      // Get user ID from the current user context or profile data
      const userId =
        user?.uid ||
        profileData?.uid ||
        queryClient.getQueryData('currentUserId');

      if (!userId) {
        console.error('Cannot update profile: No user ID available');
        toast.error(t('Failed to update profile: User ID is missing'));
        return;
      }

      // For development - replace with secure logging in production
      if (process.env.NODE_ENV !== 'production') {
        console.log('Starting profile update with data:', {
          userId: '[REDACTED]',
          name: formData.name,
          company: formData.Company,
          hasProfileImage: !!profileImageUrl,
        });
      }

      // Prepare the update payload
      const updatePayload = {
        uid: userId,
        name: formData.name,
        Company: formData.Company,
        profilepicurl: profileImageUrl,
      };

      // Additional validation
      if (!updatePayload.name || !updatePayload.Company) {
        toast.error(t('Name and Company are required'));
        return;
      }

      try {
        await updateProfile.mutateAsync(updatePayload); // Use mutateAsync from the new central data module
        toast.success(t('Profile updated successfully'));
        handleClose();
      } catch (updateError) {
        console.error('Profile update call failed:', updateError);
        // Show specific error message
        toast.error(updateError.message || t('Failed to update profile'));
      }
    } catch (error) {
      console.error('Profile update process error:', error);
      toast.error(t('Failed to update profile'));
    }
  };

  // Handle smooth closing animation
  const handleClose = () => {
    // Add closing class to trigger animations
    document.querySelector('.profile-edit-overlay').classList.add('closing');

    // Wait for animation to complete before actually closing
    setTimeout(() => {
      onClose();
    }, 300); // Match the fadeOut animation duration
  };

  return (
    <div className="profile-edit-overlay">
      <div className="profile-edit-content profile-modal">
        <h2 className="modal-title">{t('edit_profile')}</h2>

        <div className="profile-preview">
          {profilePicUrl ? (
            <img
              src={profilePicUrl}
              alt={t('profile_picture')}
              className="profile-preview-image"
            />
          ) : (
            <div className="profile-preview-placeholder">
              {formData.name.charAt(0) || '?'}
            </div>
          )}
        </div>

        <form onSubmit={handleSubmit} className="profile-form">
          <div className="form-group">
            <label htmlFor="name">{t('name')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              placeholder={t('enter_your_name')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="company">{t('company')}</label>
            <input
              type="text"
              id="company"
              name="Company"
              value={formData.Company}
              onChange={handleChange}
              required
              placeholder={t('enter_your_company')}
            />
          </div>

          <div className="form-group file-upload">
            <label htmlFor="profileImage">{t('profile_picture')}</label>
            <div className="file-input-wrapper">
              <button type="button" className="file-input-button">
                {t('choose_file')}
              </button>
              <input
                type="file"
                id="profileImage"
                name="profileImage"
                accept="image/*"
                onChange={handleChange}
                className="file-input"
              />
              <span className="file-name">
                {formData.profileImage
                  ? formData.profileImage.name
                  : t('no_file_chosen')}
              </span>
            </div>
          </div>

          {uploadProfilePicture.isLoading && (
            <div className="upload-progress">
              <div className="progress-bar">
                <div className="progress-bar-inner"></div>
              </div>
              <p>{t('uploading_image')}</p>
            </div>
          )}

          <div className="form-actions">
            <button
              type="submit"
              className="submit-btn"
              disabled={
                uploadProfilePicture.isLoading || updateProfile.isLoading
              }
            >
              {updateProfile.isLoading ? t('saving') : t('Save')}
            </button>
            <button type="button" onClick={handleClose} className="cancel-btn">
              {t('cancel')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

ProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  profileData: PropTypes.shape({
    name: PropTypes.string,
    Company: PropTypes.string,
    profilepicurl: PropTypes.string,
    uid: PropTypes.string, // Add the uid prop validation
  }).isRequired,
};

export default ProfileModal;
