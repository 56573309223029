import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import LoadingPage from '../Spinner/LoadingPage.js';
import Gantt from 'frappe-gantt';
import '../Gantt/GanttChart.css';
import Scroller from './Scroller.js';
import { useTranslation } from 'react-i18next';
import { useTasks, useTaskMutations } from '../../hooks/useTasks.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Gantt/frappe-gantt.css';
import TaskAssignee from '../Gantt/TaskAssignee.js';
import { QueryKeys } from '../../constants/queryKeys.js';
import TaskActionButtons from '../TaskActions/TaskActionButtons.js';

// Formattering og hjelpefunksjoner
const formatDependencies = {
  toString: (dep) => {
    if (!dep && dep !== 0) return '';
    if (Array.isArray(dep)) {
      return dep.length > 0 ? dep[0].toString() : '';
    }
    if (typeof dep === 'number') return dep.toString();
    return dep.toString();
  },
  toNumber: (dep) => {
    if (!dep && dep !== 0) return null;
    if (Array.isArray(dep)) {
      return dep.length > 0 ? parseInt(dep[0]) : null;
    }
    if (typeof dep === 'number') return dep;
    const parsed = parseInt(dep);
    return isNaN(parsed) ? null : parsed;
  },
};

const GanttChart = () => {
  const { t } = useTranslation();
  const ganttRef = useRef(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const tableRef = useRef(null);
  const [viewMode, setViewMode] = useState('Month');
  const [temporaryTasks, setTemporaryTasks] = useState([]);
  const [originalTasks, setOriginalTasks] = useState([]);
  const [taskHistory, setTaskHistory] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const scrollerRef = useRef();
  const [localTaskValues, setLocalTaskValues] = useState({});

  const {
    data: tasks = [],
    isLoading: tasksLoading,
    refetch: refetchTasks,
  } = useTasks();

  const queryClient = useQueryClient();
  const { updateTasks, deleteTask, createTasks } = useTaskMutations();

  const updateLocalTasks = useCallback(
    (updatedTask) => {
      if (!updatedTask?.id) return;

      // Lagre historikk før endringer
      const MAX_HISTORY = 50;
      setTaskHistory((prevHistory) => {
        const newHistory = [
          ...prevHistory,
          JSON.parse(JSON.stringify(temporaryTasks)),
        ];
        return newHistory.slice(-MAX_HISTORY); // Behold kun de siste 50 endringene
      });

      // Oppdater midlertidige oppgaver
      setTemporaryTasks((oldTasks) =>
        oldTasks.map((task) =>
          task.id === updatedTask.id ? updatedTask : task
        )
      );

      // Oppdater cachen
      queryClient.setQueryData(QueryKeys.tasks.list(), (old) =>
        (old || []).map((task) =>
          task.id === updatedTask.id ? updatedTask : task
        )
      );
    },
    [temporaryTasks, queryClient]
  );

  const renderTasks = useCallback(() => {
    if (!ganttRef.current || !temporaryTasks.length) return;

    ganttRef.current.innerHTML = '';

    // Konverter oppgaver for Gantt
    const tasksForGantt = temporaryTasks.map((task) => {
      // Gjør om dependencies til string-format
      const dependencies =
        task.dependencies !== null && task.dependencies !== undefined
          ? task.dependencies.toString()
          : '';

      return {
        ...task,
        dependencies,
      };
    });

    // Opprett Gantt-diagram
    try {
      const gantt = new Gantt(ganttRef.current, tasksForGantt, {
        language: 'no',
        on_click: (task) => {
          setSelectedTask(task);
        },
        on_date_change: (task, start, end) => {
          try {
            // Valider datoer
            if (!(start instanceof Date) || !(end instanceof Date)) {
              throw new Error('Invalid date format');
            }

            // Sjekk at start er før slutt
            if (start > end) {
              toast.error(t('Start date must be before end date'));
              return;
            }

            const updatedTask = {
              ...task,
              start,
              end,
              dependencies: formatDependencies.toNumber(task.dependencies),
              isModified: true,
            };
            updateLocalTasks(updatedTask);
          } catch (error) {
            toast.error(t('Date update failed'));
          }
        },
        on_progress_change: (task, progress) => {
          try {
            // Valider progress
            const progressNum = parseInt(progress);
            if (isNaN(progressNum) || progressNum < 0 || progressNum > 100) {
              throw new Error('Invalid progress value');
            }

            const updatedTask = {
              ...task,
              progress: progressNum,
              dependencies: formatDependencies.toNumber(task.dependencies),
              isModified: true,
            };
            updateLocalTasks(updatedTask);
          } catch (error) {
            toast.error(t('Progress update failed'));
          }
        },
        on_dependency_change: (task, dependencies) => {
          try {
            const updatedTask = {
              ...task,
              dependencies: formatDependencies.toNumber(dependencies),
              isModified: true,
            };
            updateLocalTasks(updatedTask);
          } catch (error) {
            toast.error(t('Dependency update failed'));
          }
        },
        custom_popup_html: (task) => {
          // Escaping task properties to prevent XSS
          const safeName = (task.name || '')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
          const safeStart = (task.start || '')
            .toString()
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
          const safeEnd = (task.end || '')
            .toString()
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');

          return `
            <div class="popup-wrapper">
              <div class="title">${safeName}</div>
              <div class="subtitle">${safeStart} - ${safeEnd}</div>
            </div>
          `;
        },
        bar_height: window.innerWidth <= 768 ? 20 : 30,
        bar_corner_radius: 3,
        padding: window.innerWidth <= 768 ? 12 : 18,
        view_mode: window.innerWidth <= 480 ? 'Day' : viewMode,
        popup_on: false,
      });

      gantt.change_view_mode(viewMode);

      // Juster SVG-høyden
      try {
        const newHeight =
          gantt.$svg.getAttribute('height') -
          (window.innerWidth <= 768 ? 150 : 100);
        gantt.$svg.setAttribute('height', newHeight);
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('SVG height adjustment error:', error);
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Gantt rendering error:', error);
      }
      toast.error(t('Chart rendering failed'));
    }
  }, [viewMode, temporaryTasks, updateLocalTasks, t]);

  // Initialiser oppgaver
  useEffect(() => {
    if (!tasksLoading && tasks?.length > 0) {
      try {
        const formattedTasks = tasks.map((task) => ({
          ...task,
          dependencies: formatDependencies.toNumber(task.dependencies),
        }));

        setTemporaryTasks(formattedTasks);
        setOriginalTasks(JSON.parse(JSON.stringify(formattedTasks)));
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Task initialization error:', error);
        }
        toast.error(t('Failed to load tasks'));
      }
    }
  }, [tasks, tasksLoading, t]);

  // Render Gantt når oppgaver endres
  useEffect(() => {
    renderTasks();
  }, [renderTasks]);

  // Håndter lagring av oppgaver
  const handleSave = async () => {
    if (isSaving) return;

    setIsSaving(true);
    try {
      const tasksToSave = temporaryTasks.map((task) => ({
        ...task,
        id: task.id || `temp-${new Date().getTime()}`,
        dependencies: formatDependencies.toNumber(task.dependencies),
      }));

      // Del oppgaver i nye og eksisterende
      const newTasks = tasksToSave.filter((task) =>
        task.id.toString().startsWith('temp-')
      );
      const existingTasks = tasksToSave.filter(
        (task) => !task.id.toString().startsWith('temp-')
      );

      // Håndter eksisterende oppgaver
      if (existingTasks.length > 0) {
        await updateTasks.mutateAsync(existingTasks);
      }

      // Håndter nye oppgaver
      if (newTasks.length > 0) {
        await createTasks.mutateAsync(newTasks);
      }

      // Oppdater data etter lagring
      await refetchTasks();
      toast.success(t('Changes saved successfully'));
      setTaskHistory([]);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        t('Failed to save changes');

      toast.error(errorMessage);

      if (process.env.NODE_ENV === 'development') {
        console.error('Save error:', error);
      }
    } finally {
      setIsSaving(false);
    }
  };

  // Håndter angre-funksjonalitet
  const handleCancel = () => {
    if (taskHistory.length > 0) {
      const previousTasks = taskHistory[taskHistory.length - 1];
      setTaskHistory((prevHistory) => prevHistory.slice(0, -1));
      setTemporaryTasks(previousTasks);
      queryClient.setQueryData(QueryKeys.tasks.list(), previousTasks);
    } else {
      // Reset til originale oppgaver
      const originalTasksCopy = JSON.parse(JSON.stringify(originalTasks));
      setTemporaryTasks(originalTasksCopy);
      queryClient.setQueryData(QueryKeys.tasks.list(), originalTasksCopy);
    }
  };

  // Håndter sletting av oppgave
  const handleDelete = async () => {
    if (!selectedTask) {
      toast.info(t('select_task_to_delete'));
      return;
    }

    const toastId = toast.warn(
      <div>
        <p>
          {t('confirm_delete_task')}: &quot;{selectedTask.name}&quot;?
        </p>
        <div className="toast-buttons">
          <button
            onClick={async () => {
              try {
                await deleteTask.mutateAsync(selectedTask.id);

                // Oppdater lokale oppgaver
                const updatedTasks = temporaryTasks.filter(
                  (task) => task.id !== selectedTask.id
                );
                setTemporaryTasks(updatedTasks);
                queryClient.setQueryData(QueryKeys.tasks.list(), updatedTasks);
                setSelectedTask(null);

                toast.success(t('Task deleted successfully'));
              } catch (error) {
                toast.error(t('task_deleted_error'));

                if (process.env.NODE_ENV === 'development') {
                  console.error('Delete error:', error);
                }
              }
              toast.dismiss(toastId);
            }}
          >
            {t('confirm')}
          </button>
          <button onClick={() => toast.dismiss(toastId)}>{t('cancel')}</button>
        </div>
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  // Håndter endring av visningsmodus
  const handleViewChange = (mode) => {
    setViewMode(mode);
  };

  // Oppdater lokale verdier for en oppgave
  const updateTask = useCallback((id, field, value) => {
    if (!id) return;

    // Valider verdi basert på felttype
    try {
      switch (field) {
        case 'name':
          if (typeof value !== 'string') throw new Error('Name must be text');
          break;
        case 'progress': {
          const progress = parseInt(value);
          if (isNaN(progress) || progress < 0 || progress > 100)
            throw new Error('Progress must be between 0-100');
          break;
        }
        case 'start':
        case 'end':
          if (!(value instanceof Date) && isNaN(new Date(value).getTime()))
            throw new Error('Invalid date');
          break;
      }

      // Lagre oppdatering
      setLocalTaskValues((prevValues) => ({
        ...prevValues,
        [id]: {
          ...(prevValues[id] || {}),
          [field]: value,
        },
      }));
    } catch (error) {
      toast.error(error.message);
    }
  }, []);

  // Lagre oppgaveendringer
  const saveTask = useCallback(
    (id) => {
      if (!id) return;

      const updatedTask = temporaryTasks.find((task) => task.id === id);
      const localValues = localTaskValues[id];

      if (!updatedTask || !localValues) return;

      try {
        // Konverter dependencies
        const dependencies =
          localValues.dependencies !== undefined
            ? formatDependencies.toNumber(localValues.dependencies)
            : updatedTask.dependencies;

        // Lag oppdatert oppgave
        const newTask = {
          ...updatedTask,
          ...localValues,
          dependencies,
          isModified: true,
        };

        // Oppdater historikk og lokale data
        const MAX_HISTORY = 50;
        setTaskHistory((prev) => {
          const newHistory = [...prev, [...temporaryTasks]];
          return newHistory.slice(-MAX_HISTORY); // Behold kun de siste 50 endringene
        });
        setTemporaryTasks((prevTasks) =>
          prevTasks.map((task) => (task.id === id ? newTask : task))
        );
        queryClient.setQueryData(QueryKeys.tasks.list(), (old) =>
          (old || []).map((task) => (task.id === id ? newTask : task))
        );

        // Fjern lokale endringer
        setLocalTaskValues((prev) => {
          const newValues = { ...prev };
          delete newValues[id];
          return newValues;
        });

        renderTasks();
      } catch (error) {
        toast.error(t('Failed to update task'));

        if (process.env.NODE_ENV === 'development') {
          console.error('Task update error:', error);
        }
      }
    },
    [temporaryTasks, localTaskValues, queryClient, renderTasks, t]
  );

  // Beregn varighet mellom datoer
  const calculateDuration = useCallback((start, end) => {
    try {
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return 0;
      }

      const duration = Math.ceil((endDate - startDate) / (1000 * 3600 * 24));
      return duration >= 0 ? duration : 0;
    } catch (error) {
      return 0;
    }
  }, []);

  // Legg til ny oppgave
  const handleAddTask = useCallback(() => {
    try {
      const today = new Date();
      const newTask = {
        id: `temp-${new Date().getTime()}`,
        name: t('new_activity'),
        start: today,
        end: new Date(today.getTime() + 20 * 24 * 60 * 60 * 1000),
        progress: 0,
        dependencies: [],
        custom_class: '',
        assignee: '',
      };

      // Lagre historikk og legg til oppgave
      const MAX_HISTORY = 50;
      setTaskHistory((prevHistory) => {
        const newHistory = [...prevHistory, [...temporaryTasks]];
        return newHistory.slice(-MAX_HISTORY); // Behold kun de siste 50 endringene
      });
      setTemporaryTasks((prevTasks) => [...prevTasks, newTask]);
      queryClient.setQueryData(QueryKeys.tasks.list(), (old) => [
        ...(old || []),
        newTask,
      ]);
    } catch (error) {
      toast.error(t('Failed to add task'));

      if (process.env.NODE_ENV === 'development') {
        console.error('Add task error:', error);
      }
    }
  }, [temporaryTasks, queryClient, t]);

  // Tell antall ventende endringer
  const countPendingChanges = useCallback(() => {
    return taskHistory.length;
  }, [taskHistory]);

  // Finn informasjon om oppgaveeier
  const renderTaskAssignee = useCallback(
    (task) => {
      if (!task) {
        return {
          name: t('Not assigned'),
          uid: null,
          profilepicurl: null,
          Company: null,
        };
      }

      const localValue = localTaskValues[task.id];
      const currentTask = localValue || task;

      // Hvis data allerede er i oppgaven
      if (currentTask.assigneeName && currentTask.assigneePhoto) {
        return {
          name: currentTask.assigneeName || t('Not assigned'),
          uid: currentTask.assignee,
          profilepicurl: currentTask.assigneePhoto,
          Company: currentTask.assigneeCompany,
        };
      }

      // Søk etter bruker i cache
      try {
        const allUsers = queryClient.getQueryData(QueryKeys.users.all) || [];
        const assigneeUser = allUsers.find(
          (u) => u.uid === currentTask.assignee
        );

        if (assigneeUser) {
          return {
            name: assigneeUser.name,
            uid: assigneeUser.uid,
            profilepicurl: assigneeUser.profilepicurl,
            Company: assigneeUser.Company,
          };
        }
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Error finding assignee:', error);
        }
      }

      // Standardverdi
      return {
        name: t('Not assigned'),
        uid: null,
        profilepicurl: null,
        Company: null,
      };
    },
    [localTaskValues, queryClient, t]
  );

  // Vis laster-siden under lasting
  if (tasksLoading) {
    return <LoadingPage />;
  }

  return (
    <div className="gantt-page-layout">
      <div className="gantt-container-wrapper">
        <div className="button-container">
          <div className="ViewButtons">
            {['Day', 'Week', 'Month', 'Year'].map((view) => (
              <button
                key={view}
                className={`btn-view ${viewMode === view ? 'btn-active' : ''}`}
                onClick={() => handleViewChange(view)}
                aria-label={t(`Switch to ${view.toLowerCase()} view`)}
              >
                {t(view.toLowerCase())}
              </button>
            ))}
          </div>

          <TaskActionButtons
            onAdd={handleAddTask}
            onUndo={handleCancel}
            onSave={handleSave}
            onDelete={handleDelete}
            pendingChanges={countPendingChanges()}
            isSaving={isSaving}
            isLoading={tasksLoading}
          />
        </div>

        <div className="main-content">
          <div className="table-container" ref={tableRef}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-id">{t('id')}</th>
                  <th className="col-navn">{t('name')}</th>
                  <th className="col-start">{t('start')}</th>
                  <th className="col-slutt">{t('end')}</th>
                  <th className="col-varighet">{t('duration')}</th>
                  <th className="col-fremdrift">{t('progress')}</th>
                  <th className="col-avhengigheter">{t('dependencies')}</th>
                  <th className="col-klassifisering">{t('classification')}</th>
                  <th className="col-tildelt">{t('assignee')}</th>
                </tr>
              </thead>
              <tbody>
                {temporaryTasks.map((task) => (
                  <tr
                    key={task.id}
                    onClick={() => setSelectedTask(task)}
                    className={`${task.isModified ? 'row-modified' : ''} ${selectedTask?.id === task.id ? 'selected' : ''}`}
                  >
                    <td>
                      {task.id.toString().startsWith('temp')
                        ? t('new')
                        : task.id}
                    </td>
                    <td>
                      <input
                        type="text"
                        value={
                          localTaskValues[task.id]?.name || task.name || ''
                        }
                        onChange={(e) => {
                          // Begrens lengden på oppgavenavn
                          if (e.target.value.length <= 200) {
                            updateTask(task.id, 'name', e.target.value);
                          }
                        }}
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        maxLength={200}
                        aria-label={t('Task name')}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        value={
                          localTaskValues[task.id]?.start
                            ? new Date(localTaskValues[task.id].start)
                                .toISOString()
                                .split('T')[0]
                            : new Date(task.start).toISOString().split('T')[0]
                        }
                        onChange={(e) => {
                          try {
                            const newDate = new Date(e.target.value);
                            if (!isNaN(newDate.getTime())) {
                              updateTask(task.id, 'start', newDate);
                            }
                          } catch (error) {
                            // Ignorer ugyldige datoer
                          }
                        }}
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        aria-label={t('Start date')}
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        value={
                          localTaskValues[task.id]?.end
                            ? new Date(localTaskValues[task.id].end)
                                .toISOString()
                                .split('T')[0]
                            : new Date(task.end).toISOString().split('T')[0]
                        }
                        onChange={(e) => {
                          try {
                            const newDate = new Date(e.target.value);
                            if (!isNaN(newDate.getTime())) {
                              updateTask(task.id, 'end', newDate);
                            }
                          } catch (error) {
                            // Ignorer ugyldige datoer
                          }
                        }}
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        aria-label={t('End date')}
                      />
                    </td>
                    <td>
                      {calculateDuration(
                        localTaskValues[task.id]?.start || task.start,
                        localTaskValues[task.id]?.end || task.end
                      )}{' '}
                      {t('days')}
                    </td>
                    <td>
                      <select
                        value={
                          localTaskValues[task.id]?.progress !== undefined
                            ? localTaskValues[task.id].progress
                            : task.progress || 0
                        }
                        onChange={(e) =>
                          updateTask(task.id, 'progress', e.target.value)
                        }
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        aria-label={t('Task progress')}
                      >
                        <option value="0">0%</option>
                        <option value="25">25%</option>
                        <option value="50">50%</option>
                        <option value="75">75%</option>
                        <option value="100">100%</option>
                      </select>
                    </td>
                    <td>
                      <select
                        value={
                          localTaskValues[task.id]?.dependencies !== undefined
                            ? formatDependencies.toString(
                                localTaskValues[task.id].dependencies
                              )
                            : formatDependencies.toString(task.dependencies)
                        }
                        onChange={(e) =>
                          updateTask(task.id, 'dependencies', e.target.value)
                        }
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        aria-label={t('Task dependencies')}
                      >
                        <option value="">{t('no_link')}</option>
                        {temporaryTasks
                          .filter((t) => t.id !== task.id)
                          .map((t) => (
                            <option key={t.id} value={t.id}>
                              {t.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="text"
                        value={
                          localTaskValues[task.id]?.custom_class !== undefined
                            ? localTaskValues[task.id].custom_class
                            : task.custom_class || ''
                        }
                        onChange={(e) => {
                          // Begrens lengden på klassifisering
                          if (e.target.value.length <= 50) {
                            updateTask(task.id, 'custom_class', e.target.value);
                          }
                        }}
                        onBlur={() => saveTask(task.id)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            saveTask(task.id);
                          }
                        }}
                        maxLength={50}
                        aria-label={t('Task classification')}
                      />
                    </td>
                    <td className="assignee-cell">
                      <TaskAssignee
                        currentAssignee={renderTaskAssignee(task)}
                        context="gantt-table"
                        onAssigneeSelect={(user) => {
                          if (!user?.uid) return;

                          try {
                            // Lagre historikk
                            const MAX_HISTORY = 50;
                            setTaskHistory((prev) => {
                              const newHistory = [...prev, [...temporaryTasks]];
                              return newHistory.slice(-MAX_HISTORY); // Behold kun de siste 50 endringene
                            });

                            // Oppdater oppgave med ny ansvarlig
                            const updatedTask = {
                              ...task,
                              assignee: user.uid,
                              assigneeName: user.name,
                              assigneePhoto: user.profilepicurl,
                              assigneeCompany: user.Company,
                              isModified: true,
                            };

                            // Oppdater lokale data og cache
                            setTemporaryTasks((prevTasks) =>
                              prevTasks.map((t) =>
                                t.id === task.id ? updatedTask : t
                              )
                            );
                            queryClient.setQueryData(
                              QueryKeys.tasks.list(),
                              (old) =>
                                (old || []).map((t) =>
                                  t.id === task.id ? updatedTask : t
                                )
                            );
                          } catch (error) {
                            toast.error(t('Failed to assign user'));

                            if (process.env.NODE_ENV === 'development') {
                              console.error('Assignee update error:', error);
                            }
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="gantt-container" ref={ganttRef} />
        </div>

        <div className="scroll-buttons">
          <Scroller ref={scrollerRef} tableRef={tableRef} ganttRef={ganttRef} />
        </div>
      </div>
      <ToastContainer position="top-center" />
    </div>
  );
};

export default GanttChart;
