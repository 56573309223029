import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../../config/axios.js';
import ProfileCardContainer from './ProfileCardContainer.js'; // Ensure this is used
import { useFollowing } from '../../../hooks/useFollowing.js';
import { useUser } from '../../../UserContext.js';
import './SocialCards.css'; // Import our new CSS file
import LoadingPage from '../../Spinner/LoadingPage.js';

const API_UNFOLLOW = '/api/unfollowuser'; // Corrected endpoint URL

const Following = ({ uid, onUpdateFollowing }) => {
  const { user, isLoading: userLoading } = useUser();
  const { t } = useTranslation();

  const effectiveUid = user?.uid || uid;
  const {
    data: following = [],
    isLoading: followingLoading,
    refetch: refetchFollowing,
  } = useFollowing(effectiveUid);

  const handleUpdateFollowing = useCallback(
    async (isFollowing, targetUserId) => {
      try {
        if (!isFollowing) {
          await axiosInstance.delete(API_UNFOLLOW, {
            data: { followerUid: effectiveUid, followedUid: targetUserId },
          });

          await refetchFollowing();
          onUpdateFollowing?.(false, targetUserId);
        }
      } catch (error) {
        console.error('Unfollow error:', error);
        throw error;
      }
    },
    [effectiveUid, onUpdateFollowing, refetchFollowing]
  );

  if (userLoading || followingLoading) {
    return <LoadingPage />;
  }

  if (!effectiveUid) {
    return null;
  }

  return (
    <div className="following-section">
      <h3>{t('People you are following')}</h3>
      {Array.isArray(following) && following.length > 0 ? (
        following.map((followedUser) => {
          // Add validation before rendering
          if (!followedUser?.uid || !followedUser?.name) {
            console.warn('Invalid user data:', {
              uid: followedUser?.uid,
              name: followedUser?.name,
              fullData: followedUser,
            });
            return null;
          }

          return (
            <ProfileCardContainer
              key={followedUser.uid}
              user={{
                ...followedUser,
                uid: followedUser.uid,
                name: followedUser.name, // Ensure name is passed
                profilepicurl:
                  followedUser.profilepicurl || followedUser.photoURL,
                Company: followedUser.Company || followedUser.company || '',
                isFollowing: true, // Always true since these are users we're following
              }}
              currentUserId={effectiveUid}
              className="following-card"
              onUpdateFollowing={handleUpdateFollowing}
            />
          );
        })
      ) : (
        <p>{t('no_following')}</p>
      )}
    </div>
  );
};

Following.propTypes = {
  uid: PropTypes.string, // Make optional since we use effectiveUid
  onUpdateFollowing: PropTypes.func.isRequired,
};

export default React.memo(Following);
