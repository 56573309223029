import React, { useState, useCallback, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import resizeAndCompressImage from '../../../utils/resizeimg.js';
import { useUser } from '../../../UserContext.js';
import '../../Homepage/Home.css';
import LoadingPage from '../../Spinner/LoadingPage.js';
import Post from './Post.js';
import CreatePostForm from './CreatePostForm.js';
import axiosInstance from '../../../config/axios.js';
import { useTranslation } from 'react-i18next';
import { useFeed } from '../../../hooks/useFeed.js';
import { useLikeFeed } from '../../../hooks/useLikeFeed.js';
import { QueryKeys } from '../../../constants/queryKeys.js';

const Feed = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [commentInputs, setCommentInputs] = useState({});
  const [deletingPostId, setDeletingPostId] = useState(null);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useFeed();
  const { mutate: likeFeed } = useLikeFeed();

  const updateQueryCache = useCallback(
    (updater) => {
      queryClient.setQueryData(QueryKeys.feed.list(1), (old) => {
        if (!old) return old;
        return updater(old);
      });
    },
    [queryClient]
  );

  const handleDeleteConfirmed = useCallback(
    async (postId) => {
      setDeletingPostId(postId);
      const userId = user?.uid;
      if (!userId) {
        toast.error('User not logged in');
        return;
      }

      const previousData = queryClient.getQueryData(['posts']);

      updateQueryCache((posts) => posts.filter((post) => post.id !== postId));

      try {
        const deleteUrl = `/api/deletefeedpost/${postId}?userUid=${userId}`;
        await axiosInstance.delete(deleteUrl); // Fjernet success toast
      } catch (error) {
        if (previousData) {
          ['posts', 'infinite-posts'].forEach((queryKey) => {
            queryClient.setQueryData(queryKey, previousData);
          });
        }
        console.error('Delete post error:', error);
        toast.error('Failed to delete post');
      } finally {
        setDeletingPostId(null);
      }
    },
    [queryClient, user?.uid, updateQueryCache]
  );

  const handleDeletePost = useCallback(
    async (postId) => {
      const toastId = toast.warn(
        <div>
          <p>{t('confirm_delete_post')}</p>
          <div className="toast-buttons">
            <button
              onClick={() => {
                handleDeleteConfirmed(postId);
                toast.dismiss(toastId);
              }}
            >
              {t('confirm')}
            </button>
            <button onClick={() => toast.dismiss(toastId)}>
              {t('cancel')}
            </button>
          </div>
        </div>,
        {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
        }
      );
    },
    [t, handleDeleteConfirmed]
  );

  const handleImageUpload = async (newPost) => {
    let oldData;
    let optimisticPost;
    try {
      if (!newPost.description.trim() || !newPost.feedImage) {
        toast.error('Description and image are required');
        return;
      }

      setLoading(true);
      const userId = user?.uid;

      if (!userId) {
        throw new Error('User not authenticated');
      }

      console.log('Step 1: Starting upload process with:', {
        description: newPost.description,
        fileName: newPost.feedImage.name,
        fileSize: newPost.feedImage.size,
        fileType: newPost.feedImage.type,
      });

      optimisticPost = {
        id: Date.now(),
        userUid: userId,
        userImage: queryClient.getQueryData('profilepicurl') || '',
        username: queryClient.getQueryData('currentUserName'),
        description: newPost.description,
        imageUrl: 'pending',
        likes: 0,
        comments: [],
        created_at: new Date().toISOString(),
      };

      oldData = queryClient.getQueryData(['posts']);

      updateQueryCache((posts) => [optimisticPost, ...(posts || [])]);

      console.log('Starting image compression for:', newPost.feedImage.name);
      const compressedFile = await resizeAndCompressImage(newPost.feedImage);
      console.log('Image compressed:', {
        originalSize: newPost.feedImage.size,
        compressedSize: compressedFile.size,
        reduction: `${((1 - compressedFile.size / newPost.feedImage.size) * 100).toFixed(2)}%`,
      });

      // Use process.env.REACT_APP_BLOBCDN
      const cdnUrl =
        process.env.REACT_APP_BLOBCDN || 'https://plancooblobcdn.azureedge.net';
      console.log('Using CDN URL:', cdnUrl);

      console.log('Creating feed entry with base URL:', cdnUrl);
      const feedResponse = await axiosInstance.post('/api/CreateFeed', {
        feed: {
          user_uid: userId,
          description: newPost.description,
          feedheader: '',
          file_name: newPost.feedImage.name,
          image_url: cdnUrl,
        },
      });

      console.log('Feed Response:', feedResponse.data);

      if (!feedResponse.data?.feedId) {
        throw new Error('No feed ID received');
      }

      const newFeedId = feedResponse.data.feedId;
      console.log('Feed created with ID:', newFeedId);

      // Get SAS URL with error handling
      try {
        const sasResponse = await axiosInstance.get('/api/feedpictures', {
          params: {
            fileName: newPost.feedImage.name,
            newFeedId,
            containerName: 'profile-pictures',
          },
        });

        console.log('SAS Response:', sasResponse.data);

        if (!sasResponse.data?.sasUrl) {
          throw new Error('Invalid SAS URL received');
        }

        // Upload to blob storage
        const uploadResponse = await fetch(sasResponse.data.sasUrl, {
          method: 'PUT',
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': 'image/jpeg',
          },
          body: compressedFile,
        });

        console.log('Upload Response:', uploadResponse);

        if (!uploadResponse.ok) {
          throw new Error(`Upload failed: ${uploadResponse.statusText}`);
        }

        // Update UI with final image URL
        const finalImageUrl = `${cdnUrl}/profile-pictures/${newFeedId}-${newPost.feedImage.name}`;

        updateQueryCache((posts) =>
          posts.map((post) =>
            post.id === optimisticPost.id
              ? {
                  ...post,
                  id: newFeedId,
                  imageUrl: finalImageUrl.trim(),
                }
              : post
          )
        );
      } catch (uploadError) {
        console.error('Upload process error:', uploadError);
        // If SAS/upload fails, we should clean up the feed
        await axiosInstance.delete(
          `/api/deletefeedpost/${newFeedId}?userUid=${userId}`
        );
        throw uploadError;
      }
    } catch (error) {
      if (oldData) {
        ['posts', 'infinite-posts'].forEach((queryKey) => {
          queryClient.setQueryData(queryKey, oldData);
        });
      } else if (optimisticPost) {
        updateQueryCache((posts) =>
          posts.filter((post) => post.id !== optimisticPost.id)
        );
      }
      console.error('Upload error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status,
      });
      toast.error(
        error.response?.data?.details ||
          error.message ||
          'Failed to create post'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleLike = useCallback(
    (postId) => {
      const userId = user?.uid;
      if (!userId) {
        toast.error('Du må være logget inn for å like innlegg');
        return;
      }

      likeFeed({ feed_id: postId, user_uid: userId });
    },
    [user?.uid, likeFeed]
  );

  useEffect(() => {
    // Create intersection observer to detect when posts are in viewport
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            // Add staggered delay based on post index
            setTimeout(() => {
              entry.target.classList.add('in-viewport');
            }, index * 100); // 100ms delay between each post animation
            observer.unobserve(entry.target); // Stop observing once animated
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe all post elements
    document.querySelectorAll('.post').forEach((post) => {
      observer.observe(post);
    });

    return () => observer.disconnect();
  }, [data]); // Re-run when posts data changes

  return (
    <div className="feed">
      <CreatePostForm handleImageUpload={handleImageUpload} loading={loading} />
      <div className="post-feed">
        {error && <p className="error-message">{error.message}</p>}
        {isLoading ? (
          <LoadingPage />
        ) : !data || data.length === 0 ? (
          <p>No posts to show.</p>
        ) : (
          data.map((post) => (
            <Post
              key={post.id}
              post={post}
              handleDeletePost={handleDeletePost}
              handleLike={handleLike}
              deleting={deletingPostId === post.id}
              commentInputs={commentInputs}
              setCommentInputs={setCommentInputs}
              commentFeedAPI={process.env.REACT_APP_ADD_COMMENT_API}
              deleteCommentApi={process.env.REACT_APP_DELETE_FEED_COMMENT}
              feed_id={post.id}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default Feed;
