import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineDelete } from 'react-icons/ai';
import LoadingPage from '../../Spinner/LoadingPage.js';

const Comment = ({ comment, feed_id, handleDeleteComment, deleting }) => {
  if (!comment || typeof comment !== 'object') {
    console.error('Invalid comment data:', comment);
    return null;
  }

  return (
    <div className="comment">
      <div className="comment-header">
        <img
          src={comment.commenter_profilepic || '/default-avatar.png'}
          alt={`${comment.username || 'Anonymous'}'s profile`}
          className="comment-user-image"
        />
        <div className="comment-content">
          <div className="comment-details">
            <span className="comment-user-name">
              {comment.username || 'Anonymous'}
            </span>
            <span className="comment-text">{comment.text || ''}</span>
            <span className="comment-time">
              {comment.comment_created_at
                ? new Date(comment.comment_created_at).toLocaleString('no-NO', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                : ''}
            </span>
          </div>
          <div className="comment-actions">
            {deleting ? (
              <LoadingPage />
            ) : (
              <button
                onClick={() => handleDeleteComment(comment.comment_id, feed_id)}
                className="delete-button-comment"
              >
                <AiOutlineDelete size={20} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  feed_id: PropTypes.number.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
};

export default Comment;
