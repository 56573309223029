import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import './Legal.css';
import SEOHelmet from '../SEO/SEOHelmet.js';

const LegalDocument = ({ documentPath, title }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetch(documentPath)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error loading document:', error);
        setLoading(false);
      });
  }, [documentPath]);

  if (loading) {
    return (
      <div className="legal-container">
        <div className="legal-content">
          <p>Loading document...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="legal-container">
      <SEOHelmet
        title={`${title} - PlanCoo`}
        description={`Les våre ${title.toLowerCase()} for å forstå dine rettigheter og våre forpliktelser når du bruker PlanCoo.`}
        url={`https://www.plancoo.com/${title.toLowerCase().replace(/\s+/g, '-')}`}
      />

      <div className="legal-content">
        <h1>{title}</h1>
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  );
};

LegalDocument.propTypes = {
  documentPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default LegalDocument;
