import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import logger from './utils/logger.js';

const DevPasswordGate = ({ children }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();

  // Sjekk om brukeren er en søkemotor eller bot
  const isSearchBot = useMemo(() => {
    if (typeof navigator === 'undefined') return false;

    const botPatterns = [
      'googlebot',
      'bingbot',
      'yandex',
      'baiduspider',
      'facebookexternalhit',
      'twitterbot',
      'rogerbot',
      'linkedinbot',
      'embedly',
      'quora link preview',
      'showyoubot',
      'outbrain',
      'pinterest',
      'slackbot',
      'vkShare',
      'W3C_Validator',
      'lighthouse',
      'chrome-lighthouse',
    ];

    const userAgent = navigator.userAgent.toLowerCase();
    return botPatterns.some((bot) => userAgent.includes(bot.toLowerCase()));
  }, []);

  // Hent API-endepunkt fra miljøvariabel med fallback
  const verifyEndpoint =
    process.env.REACT_APP_VERIFY_PASSWORD_API || '/api/VerifyPassword';

  // Memoiser publicPaths så den ikke gjenskapes ved hver rendering
  const publicPaths = useMemo(() => {
    // Legg til alle sider du ønsker indeksert av søkemotorer
    const paths = [
      '/',
      '/LandingPage',
      '/terms',
      '/privacy',
      '/dpa',
      '/subscriptions', // Legg til for søkemotorindeksering
    ];

    // Legg til flere utviklingsstier hvis i utviklingsmodus
    if (process.env.NODE_ENV === 'development') {
      // Du kan legge til flere stier som bare skal være åpne i utvikling
    }

    return paths;
  }, []);

  const isPublicPage = publicPaths.includes(location.pathname);

  useEffect(() => {
    const storedAuth = sessionStorage.getItem('devSiteAccess');
    if (storedAuth) {
      setIsAuthorized(true);
    }

    // Logger bare i utviklingsmodus
    if (process.env.NODE_ENV === 'development') {
      logger.log('DevPasswordGate: Public paths:', publicPaths);
      logger.log('DevPasswordGate: Current path:', location.pathname);
      logger.log('DevPasswordGate: Is public page:', isPublicPage);
      logger.log('DevPasswordGate: Is authorized:', !!storedAuth);
      logger.log('DevPasswordGate: Is search bot:', isSearchBot);
    }
  }, [location.pathname, isPublicPage, publicPaths, isSearchBot]);

  // Tillat søkebots å komme gjennom uansett side
  if (isPublicPage || isAuthorized || isSearchBot) {
    return children;
  }

  // Oppdater handleSubmit-funksjonen
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);
    setError('');

    try {
      // Bruk lokal validering i utviklingsmodus, API i produksjon
      if (process.env.NODE_ENV === 'development') {
        // Simuler nettverksforsinkelse
        await new Promise((resolve) => setTimeout(resolve, 800));

        // Erstatt 'test-password' med ønsket utviklings-passord
        if (password === '') {
          const mockToken = Math.random().toString(36).substring(2);
          sessionStorage.setItem('devSiteAccess', mockToken);
          setIsAuthorized(true);
        } else {
          setError('Feil passord');
        }
      } else {
        // Legg til logging
        logger.log('Sending request to:', verifyEndpoint);

        try {
          const response = await fetch(verifyEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ password }),
          });

          logger.log('Response status:', response.status);

          if (!response.ok) {
            if (response.status === 500) {
              setError('Server feil. Vennligst kontakt administrator.');
              logger.error('Server error (500) from API endpoint');
              return;
            }

            setError(`API feil: ${response.status}`);
            return;
          }

          const data = await response.json();
          logger.log('Response data:', data);

          if (data.authorized) {
            sessionStorage.setItem('devSiteAccess', data.token);
            setIsAuthorized(true);
          } else {
            setError('Feil passord');
          }
        } catch (err) {
          logger.error('Fetch error details:', err);
          setError(`Det oppstod en feil: ${err.message}`);
        }
      }
    } catch (err) {
      setError('Det oppstod en feil. Vennligst prøv igjen.');
      // Logg bare i utviklingsmodus
      if (process.env.NODE_ENV === 'development') {
        logger.error('Error verifying password:', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="password-gate"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
      }}
    >
      <div
        className="password-form-container"
        style={{
          background: 'rgba(255, 255, 255, 0.9)',
          padding: '40px',
          borderRadius: '20px',
          boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
          maxWidth: '500px',
          width: '90%',
          textAlign: 'center',
        }}
      >
        <h2 style={{ color: '#558392' }}>PlanCoo is under construction</h2>
        <p>Please enter DevOps password</p>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            style={{
              width: '100%',
              padding: '12px',
              margin: '10px 0',
              border: '1px solid #ddd',
              borderRadius: '5px',
            }}
          />
          <button
            type="submit"
            disabled={isLoading}
            style={{
              backgroundColor: isLoading ? '#cccccc' : '#558392',
              color: 'white',
              border: 'none',
              padding: '12px 20px',
              borderRadius: '5px',
              cursor: isLoading ? 'not-allowed' : 'pointer',
              fontWeight: 'bold',
              marginTop: '10px',
            }}
          >
            {isLoading ? 'Logger inn...' : 'Log in'}
          </button>
        </form>
        {isLoading && <p>Loading...</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

// Legg til PropTypes for å fikse validerings-feilen
DevPasswordGate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DevPasswordGate;
