import { useMutation, useQueryClient } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';
import { toast } from 'react-toastify';
import logger from '../utils/logger.js'; // Import the logger utility
// Tracking actual API calls and pending state changes
const pendingLikes = new Map();
const lastLikeTime = new Map();
const pendingStateChanges = new Map();

const processPendingStateChange = async (feed_id, user_uid) => {
  const desiredState = pendingStateChanges.get(feed_id);
  if (desiredState !== undefined) {
    logger.log('Processing pending state change:', { feed_id, desiredState });
    pendingStateChanges.delete(feed_id);

    // Make the actual API call with the final desired state
    return await axiosInstance.post('/api/LikeFeed', {
      feed_id,
      user_uid,
    });
  }
  return null;
};

const likeFeed = async ({ feed_id, user_uid }) => {
  const now = Date.now();
  const lastTime = lastLikeTime.get(feed_id);

  if (lastTime && now - lastTime < 20000) {
    logger.log('Queuing state change for later');
    // Lagre ønsket tilstand
    pendingStateChanges.set(feed_id, !pendingStateChanges.get(feed_id));

    // Sett opp timeout for å prosessere endringen
    if (!pendingLikes.has(feed_id)) {
      pendingLikes.set(
        feed_id,
        setTimeout(async () => {
          pendingLikes.delete(feed_id);
          lastLikeTime.delete(feed_id);
          try {
            await processPendingStateChange(feed_id, user_uid);
          } catch (error) {
            logger.error('Failed to process pending state change:', error);
          }
        }, 20000)
      );
    }

    return Promise.resolve({ status: 'queued' });
  }

  // Clear any pending timeouts
  if (pendingLikes.has(feed_id)) {
    clearTimeout(pendingLikes.get(feed_id));
    pendingLikes.delete(feed_id);
  }

  logger.log('Making immediate API call for like/unlike');
  try {
    const response = await axiosInstance.post('/api/LikeFeed', {
      feed_id,
      user_uid,
    });
    lastLikeTime.set(feed_id, now);
    pendingStateChanges.delete(feed_id); // Clear any pending state
    return response.data;
  } catch (error) {
    logger.error('Like operation failed:', error);
    throw error;
  }
};

export const useLikeFeed = () => {
  const queryClient = useQueryClient();

  return useMutation(likeFeed, {
    onMutate: async ({ feed_id }) => {
      await queryClient.cancelQueries(QueryKeys.feed.list(1));
      const previousPosts = queryClient.getQueryData(QueryKeys.feed.list(1));

      // Optimistic update
      queryClient.setQueryData(QueryKeys.feed.list(1), (old) => {
        if (!old) return old;
        return old.map((post) => {
          if (post.id === feed_id) {
            const isCurrentlyLiked = post.liked_by_current_user;
            return {
              ...post,
              likes_count: isCurrentlyLiked
                ? post.likes_count - 1
                : post.likes_count + 1,
              liked_by_current_user: !isCurrentlyLiked,
            };
          }
          return post;
        });
      });

      return { previousPosts };
    },

    onError: (err, variables, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData(QueryKeys.feed.list(1), context.previousPosts);
      }
      toast.error('Kunne ikke oppdatere like');
    },

    onSuccess: (data) => {
      // Only show toast for actual API errors, not for debounced/pending operations
      if (data.status === 'error') {
        toast.error('Kunne ikke oppdatere like');
      }
    },
  });
};
