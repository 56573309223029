import React from 'react';

export default function WebsiteSchema() {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          name: 'PlanCoo',
          url: 'https://www.plancoo.com/',
          description:
            'Moderne prosjektstyring med Gantt-diagrammer, oppgavestyring og samarbeid.',
          potentialAction: {
            '@type': 'SearchAction',
            target: 'https://www.plancoo.com/search?q={search_term_string}',
            'query-input': 'required name=search_term_string',
          },
        }),
      }}
    />
  );
}
