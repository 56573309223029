import './firebaseConfig.js';
import { auth } from './firebaseConfig.js';
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import PropTypes from 'prop-types';
import i18n from './components/Language/i18n.js';
import SignUp from './components/Login/SignUp.js';
import MyNavbar from './components/Navbar/NavBar.js';
import GanttChart from './components/Gantt/GanttChart.js';
import MobileProjects from './components/Gantt/MobileProjects.js';
import { UserProvider } from './UserContext.js';
import Home from './components/Homepage/Home.js';
import CompanyPage from './components/Company/CompanyPage.js';
import { useUser } from './UserContext.js';
import { QueryClient, QueryClientProvider } from 'react-query';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LandingPage from './components/LandingPage/LandingPage.js';
import { fetchUserDetails } from './services/userService.js';
import LoadingPage from './components/Spinner/LoadingPage.js';
import PaymentSuccess from './components/LemonSqueezy/PaymentSuccess.js';
import PaymentCancelled from './components/LemonSqueezy/PaymentCancelled.js';
import SubscriptionPlans from './components/LemonSqueezy/SubscriptionPlans.js';
import SubscriptionManager from './components/LemonSqueezy/SubscriptionManager.js';
import DevPasswordGate from './DevPasswordGate.js';
import LegalDocument from './components/Legal/LegalDocument.js';
import WebsiteSchema from './components/JsonLd/WebsiteSchema.js';
import CleanupTokens from './components/auth/CleanupTokens.js';
import logger from './utils/logger.js';
import axiosInstance from './config/axios.js';
import { TokenService } from './services/tokenService.js';
import { setupAuthHealthMonitor } from './utils/authDiagnostics.js';

const TOAST_DURATION = 1000; // Set toast duration to 2.5 seconds

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * 60 * 1000, // 5 minutes as default staleTime
    },
  },
}); // Create a QueryClient instance

const RequireAuth = ({ children }) => {
  const { user } = useUser();
  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await auth.currentUser?.reload();
        setIsVerifying(false);
      } catch (error) {
        logger.error('Auth verification failed:', error);
        setIsVerifying(false);
      }
    };

    checkAuth();
  }, []);

  if (isVerifying) {
    return <LoadingPage />;
  }

  if (!user || !auth.currentUser) {
    return <Navigate to="/SignUp" />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

function App() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    TokenService.setupTokenRefresh();
  }, []);

  // Set up auth monitoring
  useEffect(() => {
    setupAuthHealthMonitor();
  }, []);

  // Handle DOM ready state
  useEffect(() => {
    const handleDOMLoad = () => {
      const rootElement = document.getElementById('root');
      if (rootElement) {
        rootElement.classList.add('app-loaded');
      }
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMLoad);
    } else {
      handleDOMLoad();
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMLoad);
    };
  }, []);

  useEffect(() => {
    // Clean up any existing tokens across all domains
    localStorage.removeItem('firebaseToken');
    localStorage.removeItem('token');
    localStorage.removeItem('uid');

    // Try to clean up tokens using the URL from environment variablem environment variable
    try {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = process.env.REACT_APP_CLEANUP_TOKENS_URL;

      document.body.appendChild(iframe);
      setTimeout(() => document.body.removeChild(iframe), 1000);
    } catch (error) {
      logger.error('Failed to cleanup tokens', error);
    }
  }, []);

  useEffect(() => {
    let isInitialMount = true;

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (isInitialMount) {
        isInitialMount = false;
        setLoading(false);
        if (user) {
          setUser(user);
          // Bare sett brukerdata uten toast på initial mount
          try {
            const userDetails = await fetchUserDetails(user.uid);
            updateUserQueries(userDetails);
          } catch (error) {
            logger.error('Failed to fetch user details:', error);
          }
        }
        return;
      }

      if (user) {
        setUser(user);
        try {
          const userDetails = await fetchUserDetails(user.uid);
          updateUserQueries(userDetails);

          // Pre-load profilbilde etter autentisering
          if (userDetails?.profilepicurl) {
            await new Promise((resolve) => {
              const img = new Image();
              img.onload = resolve;
              img.onerror = resolve; // Fortsett selv ved feil
              img.src = userDetails.profilepicurl;
              logger.log('Pre-loading profile image');
            });
          }

          // Nå er bildet lastet og klart

          toast.success('Logged in successfully!', {
            autoClose: TOAST_DURATION,
          });
        } catch (error) {
          logger.error('Failed to fetch user details:', error);
          toast.error('Failed to fetch user data');
        }
      } else if (!isInitialMount) {
        // Vis bare logout melding hvis det ikke er initial mount
        setUser(null);
        clearUserQueries();
        toast.info('Logged out successfully.', { autoClose: TOAST_DURATION });
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const updateHtmlLang = () => {
      const currentLang = i18n.language;
      document.documentElement.lang = currentLang;
    };

    updateHtmlLang();
    i18n.on('languageChanged', updateHtmlLang);

    return () => {
      i18n.off('languageChanged', updateHtmlLang);
    };
  }, []);

  useEffect(() => {
    // Debug helper
    const debugAuth = () => {
      const hasToken = !!axiosInstance.defaults.headers.common['Authorization'];
      logger.log('Auth status:', {
        isAuthenticated: !!auth.currentUser,
        hasAuthHeader: hasToken,
      });
    };

    // Run debug check on auth state change
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        debugAuth();
      } else {
        logger.log('User signed out');
      }
    });

    return () => unsubscribe();
  }, []);

  // Helper functions
  const updateUserQueries = (userDetails) => {
    queryClient.setQueryData(['userData', userDetails.uid], userDetails);
  };

  const clearUserQueries = () => {
    queryClient.removeQueries('userData');
  };

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <UserProvider>
          {/* Navbar - always visible */}
          <MyNavbar user={user} />
          <WebsiteSchema />

          {/* Single Routes component for all routes */}
          <DevPasswordGate>
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/LandingPage" element={<LandingPage />} />
              <Route
                path="/terms"
                element={
                  <LegalDocument
                    documentPath="/legal/TermServ.md"
                    title="Terms of Service for PlanCoo"
                  />
                }
              />
              <Route
                path="/privacy"
                element={
                  <LegalDocument
                    documentPath="/legal/PrivSta.md"
                    title="Privacy Policy for PlanCoo"
                  />
                }
              />
              <Route
                path="/dpa"
                element={
                  <LegalDocument
                    documentPath="/legal/Dpa.md"
                    title="Data Processing Agreement for PlanCoo"
                  />
                }
              />
              <Route path="/cleanup-tokens" element={<CleanupTokens />} />

              {/* Auth-protected routes with DevPasswordGate */}
              <Route
                path="/SignUp"
                element={
                  <DevPasswordGate>
                    {user ? <Navigate to="/Home" /> : <SignUp />}
                  </DevPasswordGate>
                }
              />
              <Route
                path="/signup"
                element={
                  <DevPasswordGate>
                    {user ? <Navigate to="/Home" /> : <SignUp />}
                  </DevPasswordGate>
                }
              />
              <Route
                path="/subscriptions"
                element={
                  <DevPasswordGate>
                    <SubscriptionPlans />
                  </DevPasswordGate>
                }
              />
              <Route
                path="/payment-success"
                element={
                  <DevPasswordGate>
                    <PaymentSuccess />
                  </DevPasswordGate>
                }
              />
              <Route
                path="/payment-cancelled"
                element={
                  <DevPasswordGate>
                    <PaymentCancelled />
                  </DevPasswordGate>
                }
              />

              {/* User authenticated routes */}
              <Route
                path="/Home"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <Home />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
              <Route
                path="/home"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <Home />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
              <Route
                path="/Gantt"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <GanttChart />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
              <Route
                path="/company"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <CompanyPage />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
              <Route
                path="/mobile-projects"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <MobileProjects />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
              <Route
                path="/SubscriptionManager"
                element={
                  <DevPasswordGate>
                    <RequireAuth>
                      <SubscriptionManager />
                    </RequireAuth>
                  </DevPasswordGate>
                }
              />
            </Routes>
          </DevPasswordGate>
        </UserProvider>
        {/* ToastContainer remains the same */}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </QueryClientProvider>
  );
}

export default App;
