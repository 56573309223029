import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import './SignUp.css';
import GoogleBtn from '../Buttons/Auth/GoogleBtn.js';
import MicrosoftBtn from '../Buttons/Auth/MicrosoftBtn.js';
import { useUser } from '../../UserContext.js'; // Import useUser
import { useQueryClient } from 'react-query'; // Import useQueryClient
import {
  useHandleSignIn,
  useHandleUserCreation,
  useHandleUserPostSignIn,
} from '../../hooks/useAuth.js'; // Alle hooks fra useAuth.js
import { useCreateUser } from '../../services/userService.js'; // Add this import
import AuthService from '../../services/authService.js'; // Legg tilbake AuthService
import axiosInstance from '../../config/axios.js';
import MagicLinkBtn from '../Buttons/Auth/MagicLinkBtn.js'; // Import the Magic Link button
import SEOHelmet from '../SEO/SEOHelmet.js'; // Legg til denne importen

const SignUp = () => {
  const navigate = useNavigate();
  const { setUser } = useUser(); // Access user from UserContext
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [initError, setInitError] = useState(false);
  const [isHandlingSignIn, setIsHandlingSignIn] = useState(false); // Flyttet hit
  const [isCreatingAccount, setIsCreatingAccount] = useState(false); // Add this state
  const queryClient = useQueryClient(); // Initialize queryClient
  const { signInWithGoogle, signInWithMicrosoft } = useHandleSignIn(); // Use custom hook
  const handleUserCreation = useHandleUserCreation(); // Use custom hook
  const handleUserPostSignIn = useHandleUserPostSignIn(); // Use custom hook
  const createUserMutation = useCreateUser(); // Add this

  // Wrap handleNewUserSetup in useCallback hook
  const handleNewUserSetup = useCallback(
    async (loggedInUser, userData) => {
      try {
        const createdUser = await createUserMutation.mutateAsync(userData);
        console.log('User created successfully:', createdUser);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        setUser({
          uid: loggedInUser.uid,
          email: loggedInUser.email,
          name: userData.name,
          company: userData.company,
          subscriptionStatus: userData.subscription_status,
          subscriptionName: userData.subscription_name,
          profilePicUrl: userData.profilepicurl,
        });

        queryClient.setQueryData(['userProfile', loggedInUser.uid], userData);
        setMessage('Welcome! Your account has been created.');
        navigate('/Home');
      } catch (error) {
        console.error('Failed to complete user setup:', error);
        AuthService.cleanup();
        setMessage('Error creating user account. Please try again.');
        throw error;
      }
    },
    [createUserMutation, navigate, queryClient, setMessage, setUser]
  ); // Add dependencies

  // Sjekk Firebase config - fjern unødvendig logging
  useEffect(() => {
    if (!process.env.REACT_APP_API_KEY) {
      console.error('Firebase configuration error');
      setInitError(true);
    }
  }, []);

  // Timer for message
  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('');
    }, 5000);
    return () => clearTimeout(timer);
  }, [message]);

  // Magic link handling
  useEffect(() => {
    const auth = getAuth();

    const checkLicensedUser = async (email, uid) => {
      try {
        const response = await axiosInstance.post('/api/checkLicensedUser', {
          email,
          uid,
        });

        return response.data;
      } catch (error) {
        console.error('Error checking licensed user:', error);
        return null;
      }
    };

    const handleSuccessfulSignIn = async (loggedInUser) => {
      try {
        // Check if user is a pre-registered licensed user
        const licenseCheck = await checkLicensedUser(
          loggedInUser.email,
          loggedInUser.uid
        );

        // User is licensed - update profile with company info
        if (licenseCheck && licenseCheck.isLicensed) {
          // Set additional user data
          const userData = {
            ...loggedInUser,
            company: licenseCheck.companyName || '',
            companyId: licenseCheck.companyId || null,
            subscription_status: 'active',
            subscription_name: 'Licensed',
            hasLicense: true,
          };

          setUser(userData);
          navigate('/Home');
        } else {
          // Regular user flow - keep your existing code here
          await handleUserPostSignIn(loggedInUser);
          navigate('/Home');
        }
      } catch (error) {
        console.error('Error in sign-in handling:', error);
        setMessage('An error occurred during sign-in');
      }
    };

    if (
      isSignInWithEmailLink(auth, window.location.href) &&
      !isHandlingSignIn
    ) {
      setIsHandlingSignIn(true); // Prevent multiple executions
      setLoading(true);

      const storedEmail =
        queryClient.getQueryData('emailForSignIn') ||
        sessionStorage.getItem('emailForSignIn');

      const handleSignIn = async (email) => {
        try {
          const result = await signInWithEmailLink(
            auth,
            email,
            window.location.href
          );
          const loggedInUser = result.user;

          // Hent og valider token via AuthService
          const token = await AuthService.getToken();
          if (!token) {
            throw new Error('Failed to get authentication token');
          }

          const isNewUser = result._tokenResponse?.isNewUser ?? false;

          // Cleanup stored email immediately
          queryClient.removeQueries('emailForSignIn');
          sessionStorage.removeItem('emailForSignIn');

          if (isNewUser) {
            const userData = {
              firebaseUserId: loggedInUser.uid,
              email: loggedInUser.email,
              name: loggedInUser.email.split('@')[0],
              subscription_name: 'Gratis',
              subscription_status: 'inaktiv',
              subscription_end: new Date().toISOString(),
              company: '',
              profilepicurl: '',
            };

            await handleNewUserSetup(loggedInUser, userData);
          } else {
            await handleSuccessfulSignIn(loggedInUser);
          }

          // Vent til brukerdata er synkronisert før navigering
          await new Promise((resolve) => setTimeout(resolve, 2000));
        } catch (error) {
          AuthService.cleanup(); // Rydd opp ved feil
          if (process.env.NODE_ENV === 'development') {
            console.error('Sign in error:', error);
          }
          setMessage('Login failed. Please try again.');
          navigate('/SignUp');
        } finally {
          setLoading(false);
          setIsHandlingSignIn(false);
        }
      };

      if (storedEmail) {
        handleSignIn(storedEmail);
      } else {
        // Ask for email only once
        const emailPrompt = window.prompt(
          'Please confirm your email to complete sign-in:'
        );
        if (emailPrompt) {
          handleSignIn(emailPrompt);
        } else {
          setLoading(false);
          setIsHandlingSignIn(false);
          navigate('/SignUp');
        }
      }
    }

    return () => {
      // Cleanup on unmount
      if (isHandlingSignIn) {
        setIsHandlingSignIn(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    setUser,
    queryClient,
    handleUserCreation,
    handleUserPostSignIn,
    isHandlingSignIn,
    createUserMutation,
    handleNewUserSetup,
  ]);

  // Render error state hvis nødvendig
  if (initError) {
    return (
      <div className="error-container">
        <h2>Configuration Error</h2>
        <p>
          The application is not properly configured. Please try again later.
        </p>
        <p>Error: Firebase configuration missing</p>
      </div>
    );
  }

  // Send magic link
  const handleSendMagicLink = async (email) => {
    if (!email) {
      setMessage('E-post er påkrevd.');
      return;
    }

    setLoading(true);
    const auth = getAuth();
    const actionCodeSettings = {
      url: `${window.location.origin}/SignUp`,
      handleCodeInApp: true,
    };

    try {
      // Lagre e-post både i queryClient og sessionStorage for ekstra sikkerhet
      queryClient.setQueryData('emailForSignIn', email);
      sessionStorage.setItem('emailForSignIn', email);

      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      setMessage(
        'Magic link sendt! Sjekk e-posten din for å fullføre innloggingen.'
      );
    } catch (error) {
      // Fjern detaljert feillogging i produksjon
      if (process.env.NODE_ENV === 'development') {
        console.error('Magic link error:', error);
      }
      setMessage('Kunne ikke sende magic link. Vennligst prøv igjen.');
    } finally {
      setLoading(false);
    }
  };

  // Håndter Google-innlogging
  const handleGoogleSignIn = async () => {
    setLoading(true);
    try {
      await signInWithGoogle(navigate, setMessage, setUser);
      navigate('/Home');
    } catch (error) {
      // Fjern detaljert feillogging i produksjon
      if (process.env.NODE_ENV === 'development') {
        console.error('Google sign-in error:', error);
      }
      setMessage(
        'Det oppstod en feil ved Google-innlogging. Vennligst prøv igjen senere.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Håndter Microsoft-innlogging
  const handleMicrosoftSignIn = async () => {
    setLoading(true);
    try {
      await signInWithMicrosoft(navigate, setMessage, setUser);
      navigate('/Home');
    } catch (error) {
      // Fjern detaljert feillogging i produksjon
      if (process.env.NODE_ENV === 'development') {
        console.error('Microsoft sign-in error:', error);
      }
      setMessage(
        'Det oppstod en feil ved Microsoft-innlogging. Vennligst prøv igjen senere.'
      );
    } finally {
      setLoading(false);
    }
  };

  // Funksjon for å håndtere passordbasert innlogging
  const handlePasswordAuth = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setMessage('Både e-post og passord er nødvendig');
      return;
    }

    setLoading(true);
    try {
      let userCredential;
      const auth = getAuth();

      if (isCreatingAccount) {
        // Create new user
        userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const userData = {
          firebaseUserId: userCredential.user.uid,
          email: email,
          name: email.split('@')[0],
          subscription_name: 'Gratis',
          subscription_status: 'inaktiv',
          subscription_end: new Date().toISOString(),
          company: '',
          profilepicurl: '',
        };
        await handleNewUserSetup(userCredential.user, userData);
        setMessage('Konto opprettet og logget inn!');
      } else {
        // Sign in existing user
        userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        setMessage('Logget inn!');
        await handleUserPostSignIn(userCredential.user);
      }

      navigate('/Home');
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setMessage('Feil passord. Prøv igjen eller bruk Magic Link.');
      } else if (error.code === 'auth/weak-password') {
        setMessage('Passordet må være minst 6 tegn langt.');
      } else if (error.code === 'auth/email-already-in-use') {
        setMessage('E-posten er allerede i bruk. Prøv å logge inn i stedet.');
      } else {
        setMessage('Pålogging feilet. Vennligst prøv igjen.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Add function to handle forgot password
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (!email) {
      setMessage('Vennligst skriv inn e-postadressen din først');
      return;
    }

    setLoading(true);
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setMessage(
        'E-post for tilbakestilling av passord er sendt. Sjekk innboksen din.'
      );
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Password reset error:', error);
      }
      setMessage(
        'Kunne ikke sende e-post for tilbakestilling av passord. Sjekk e-postadressen og prøv igjen.'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signupbackground">
      <SEOHelmet
        title="Registrer deg eller logg inn - PlanCoo"
        description="Opprett konto eller logg inn på PlanCoo for å få tilgang til vårt moderne prosjektstyringsverktøy. Enkel og sikker innlogging."
        url="https://www.plancoo.com/signup"
      />

      {/* Flyttet login beskrivelse over boksen */}
      <div className="login-description-header">
        <p>
          <strong>planCoo</strong> prioritizes{' '}
          <strong>secure, passwordless login.</strong> Sign in easily with
          Google, Microsoft, or <strong>Magic Link </strong>for a hassle-free
          experience.
        </p>
      </div>

      <div className="signUp">
        <h2>{isCreatingAccount ? 'Opprett konto' : 'Logg inn'}</h2>

        <div className="socioalSignIn">
          <GoogleBtn onClick={handleGoogleSignIn} disabled={loading}>
            {loading ? 'Logger inn...' : 'Logg inn med Google'}
          </GoogleBtn>
          <MicrosoftBtn onClick={handleMicrosoftSignIn} disabled={loading}>
            {loading ? 'Logger inn...' : 'Logg inn med Microsoft'}
          </MicrosoftBtn>
        </div>

        {/* Direct Magic Link component with no containers or wrappers */}
        <MagicLinkBtn onClick={handleSendMagicLink} disabled={loading} />

        <div className="divider">
          <span>ELLER</span>
        </div>

        <form onSubmit={handlePasswordAuth}>
          <input
            type="email"
            placeholder="Skriv inn e-posten din"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete={isCreatingAccount ? 'email' : 'username'}
            required
          />
          <div className="password-container">
            <input
              type="password"
              placeholder="Skriv inn passord"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete={
                isCreatingAccount ? 'new-password' : 'current-password'
              }
              required
            />
            {!isCreatingAccount && (
              <button
                type="button"
                className="forgot-password-btn"
                onClick={handleForgotPassword}
                disabled={loading}
              >
                Glemt passord?
              </button>
            )}
          </div>
          <button type="submit" className="btn btn-signup" disabled={loading}>
            {loading
              ? 'Behandler...'
              : isCreatingAccount
                ? 'Opprett konto'
                : 'Logg inn med passord'}
          </button>
        </form>

        {/* Toggle between creating a new account and logging in */}
        <div className="toggle-auth-method">
          <button
            onClick={() => setIsCreatingAccount(!isCreatingAccount)}
            className="link-button"
          >
            {isCreatingAccount
              ? 'Har du allerede en konto? Logg inn her →'
              : 'Ny bruker? Opprett konto her →'}
          </button>
        </div>

        {message && <p className="messageText">{message}</p>}
      </div>
    </div>
  );
};

export default SignUp;
