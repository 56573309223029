import React, { useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';

const Scroller = forwardRef((props, ref) => {
  const scrollAmount = 144;

  // Hjelpefunksjon for å sjekke gyldig DOM-element
  const isValidElement = (element) => {
    return element && typeof element.scrollTop !== 'undefined';
  };

  // Hjelpefunksjon for å hente Gantt-container sikkert
  const getGanttContainer = () => {
    try {
      if (!isValidElement(props.ganttRef.current)) return null;
      const container =
        props.ganttRef.current.querySelector('.gantt-container');
      return isValidElement(container) ? container : null;
    } catch (error) {
      console.error('Error getting gantt container:', error);
      return null;
    }
  };

  useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      try {
        if (isValidElement(props.tableRef.current)) {
          props.tableRef.current.scrollTop = 0;
        }

        const ganttContainer = getGanttContainer();
        if (ganttContainer) {
          ganttContainer.scrollTop = 0;
        }
      } catch (error) {
        console.error('Error scrolling to top:', error);
      }
    },
    scrollToBottom: () => {
      try {
        if (isValidElement(props.tableRef.current)) {
          props.tableRef.current.scrollTop =
            props.tableRef.current.scrollHeight || 0;
        }

        const ganttContainer = getGanttContainer();
        if (ganttContainer) {
          ganttContainer.scrollTop = ganttContainer.scrollHeight || 0;
        }
      } catch (error) {
        console.error('Error scrolling to bottom:', error);
      }
    },
  }));

  const handleScrollUp = () => {
    try {
      const tableElement = props.tableRef.current;
      const ganttContainer = getGanttContainer();

      // Sikker scrolling av tabell
      if (isValidElement(tableElement)) {
        const newTableScrollTop = Math.max(
          tableElement.scrollTop - scrollAmount,
          0
        );
        tableElement.scrollTo({
          top: newTableScrollTop,
          behavior: 'smooth',
        });
      }

      // Sikker scrolling av Gantt
      if (ganttContainer) {
        const newGanttScrollTop = Math.max(
          ganttContainer.scrollTop - scrollAmount,
          0
        );
        ganttContainer.scrollTo({
          top: newGanttScrollTop,
          behavior: 'smooth',
        });
      }
    } catch (error) {
      console.error('Error scrolling up:', error);
    }
  };

  const handleScrollDown = () => {
    try {
      const tableElement = props.tableRef.current;
      const ganttContainer = getGanttContainer();

      // Sikker scrolling av tabell
      if (isValidElement(tableElement)) {
        const newTableScrollTop = tableElement.scrollTop + scrollAmount;
        tableElement.scrollTo({
          top: newTableScrollTop,
          behavior: 'smooth',
        });
      }

      // Sikker scrolling av Gantt
      if (ganttContainer) {
        const newGanttScrollTop = ganttContainer.scrollTop + scrollAmount;
        ganttContainer.scrollTo({
          top: newGanttScrollTop,
          behavior: 'smooth',
        });
      }
    } catch (error) {
      console.error('Error scrolling down:', error);
    }
  };

  return (
    <div className="scroller">
      <button
        className="scroll-button"
        onClick={handleScrollUp}
        aria-label="Scroll up"
      >
        <FaArrowUp />
      </button>
      <button
        className="scroll-button"
        onClick={handleScrollDown}
        aria-label="Scroll down"
      >
        <FaArrowDown />
      </button>
    </div>
  );
});

// Prop validation
Scroller.propTypes = {
  tableRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
  ganttRef: PropTypes.shape({
    current: PropTypes.object,
  }).isRequired,
};

// Add display name
Scroller.displayName = 'Scroller';

export default Scroller;
