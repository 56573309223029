/**
 * Validerer oppgavedata
 */
export const validateTaskData = (task) => {
  // Sjekk om task er et gyldig objekt
  if (!task || typeof task !== 'object') {
    throw new Error('Invalid task data');
  }

  // Valider navn
  if (!task.name || typeof task.name !== 'string' || task.name.length > 200) {
    throw new Error('Invalid task name');
  }

  // Valider datoer
  const startDate = new Date(task.start);
  const endDate = new Date(task.end);

  if (isNaN(startDate.getTime())) {
    throw new Error('Invalid start date');
  }

  if (isNaN(endDate.getTime())) {
    throw new Error('Invalid end date');
  }

  // Valider progress
  const progress = parseInt(task.progress);
  if (isNaN(progress) || progress < 0 || progress > 100) {
    throw new Error('Invalid progress value');
  }

  // Returner sanitert oppgave
  return {
    ...task,
    name: task.name.trim().slice(0, 200),
    progress,
    custom_class: task.custom_class
      ? task.custom_class.trim().slice(0, 50)
      : '',
  };
};
