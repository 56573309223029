// src/components/Stripe/PaymentSuccess.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import axiosInstance from '../../config/axios.js';
import './PaymentSuccess.css';

const PaymentSuccess = () => {
  const [status, setStatus] = useState('loading');
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get('session_id');

    if (!sessionId) {
      setStatus('error');
      return;
    }

    // Verify session status with your backend
    const verifySession = async () => {
      try {
        await axiosInstance.get(
          `/api/VerifyCheckoutSession?sessionId=${sessionId}`
        );

        // Invalidate user data to refresh subscription status
        queryClient.invalidateQueries('customerId');
        queryClient.invalidateQueries('subscriptionStatus');
        queryClient.invalidateQueries('subscriptionName');

        setStatus('success');
      } catch (err) {
        console.error('Error verifying session:', err);
        setStatus('error');
      }
    };

    verifySession();
  }, [location, queryClient]);

  const redirectToHome = () => {
    navigate('/Home');
  };

  if (status === 'loading') {
    return (
      <div className="payment-success-container">
        <div className="payment-success-card">
          <h2>Processing your payment...</h2>
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  if (status === 'error') {
    return (
      <div className="payment-success-container">
        <div className="payment-success-card payment-error">
          <h2>Payment Verification Failed</h2>
          <p>
            We could not verify your payment. If you believe this is an error,
            please contact support.
          </p>
          <button onClick={redirectToHome}>Return to Dashboard</button>
        </div>
      </div>
    );
  }

  return (
    <div className="payment-success-container">
      <div className="payment-success-card">
        <h2>Payment Successful!</h2>
        <p>Thank you for your subscription. Your account has been upgraded.</p>
        <button onClick={redirectToHome}>Continue to Dashboard</button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
