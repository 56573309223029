import { useQuery } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';

// Only keep the necessary parts for the feed query
const fetchFeed = async () => {
  const response = await axiosInstance.get('/api/getfeeds');
  return response.data?.map((post) => ({
    id: post.feed_id,
    userUid: post.user_uid,
    username: post.username,
    userCompany: post.userCompany,
    description: post.description,
    imageUrl: post.imageUrl,
    userImage: post.userImage,
    created_at: post.created_at,
    likes_count: post.likes_count || 0,
    liked_by_current_user: post.liked_by_current_user === 1,
    comments: Array.isArray(post.comments)
      ? post.comments
      : JSON.parse(post.comments || '[]'),
  }));
};

export const useFeed = () => {
  return useQuery(QueryKeys.feed.list(1), fetchFeed, {
    staleTime: 1000 * 60 * 5, // Dataene er friske i 5 minutter
    cacheTime: 1000 * 60 * 30, // Dataene forblir i cachen i 30 minutter
    refetchOnWindowFocus: false, // Ikke hent nye data når vinduet får fokus
    refetchOnMount: false, // Ikke hent nye data når komponenten monteres på nytt
    refetchInterval: false, // Deaktiver bakgrunnsoppdatering helt
  });
};
