import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useFollowButton from '../../../hooks/useFollowButton.js'; // Add .js extension
import '../../Homepage/Home.css';
import './SocialCards.css'; // Import our new CSS file

const FollowButton = ({
  isFollowing,
  userId,
  currentUserId,
  onUpdateFollowing,
}) => {
  const { t } = useTranslation();
  const { handleFollow, isLoading } = useFollowButton(currentUserId);

  const handleClick = async (e) => {
    e.stopPropagation();
    if (isLoading || !currentUserId || !userId) return;

    try {
      await handleFollow(userId, !isFollowing, (newFollowingState) => {
        if (typeof onUpdateFollowing === 'function') {
          onUpdateFollowing(newFollowingState, userId);
        }
      });
    } catch (error) {
      console.error('Failed to update following status:', error);
    }
  };

  return (
    <button
      className={`follow-button ${isFollowing ? 'following' : ''} ${isLoading ? 'loading' : ''}`}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? t('updating') : isFollowing ? t('unfollow') : t('follow')}
    </button>
  );
};

FollowButton.propTypes = {
  isFollowing: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  onUpdateFollowing: PropTypes.func.isRequired,
};

export default FollowButton;
