import { useQuery } from 'react-query';
import axiosInstance from '../config/axios.js';
import { QueryKeys } from '../constants/queryKeys.js';
import logger from '../utils/logger.js'; // Import the logger utility
const useFollowing = (uid) => {
  return useQuery(
    QueryKeys.following.list(uid),
    async () => {
      if (!uid) return [];

      try {
        const url = `/api/getfollowing/${uid}`;
        const response = await axiosInstance.get(url);

        if (!response.data) {
          throw new Error('No data received');
        }

        return response.data.map((user) => {
          const userData = {
            uid: user.uid || user.followedUid,
            name:
              user.name || user.user_name || user.username || 'Unknown User', // Added more fallbacks
            Company: user.Company || user.company || user.user_company || '',
            profilepicurl:
              user.profilepicurl || user.photoURL || user.user_profilepic,
            email: user.email || '',
            isFollowing: true,
          };

          return userData;
        });
      } catch (error) {
        logger.error('Following fetch error:', error);
        throw error;
      }
    },
    {
      enabled: !!uid,
      staleTime: 0,
      cacheTime: 30 * 60 * 1000,
      retry: 1,
    }
  );
};

export { useFollowing };
