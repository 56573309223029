import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './MagicLinkBtn.css';
import magicLinkIcon from '../../../assets/Auth/magicLink.png';

const MagicLinkBtn = ({ onClick, disabled = false }) => {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email.trim()) {
      onClick(email);
    }
  };

  // Handle icon click to focus input or submit if email exists
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    if (email.trim() && !disabled) {
      onClick(email);
    }
  };

  return (
    <form className="custom-magic-link-btn" onSubmit={handleSubmit}>
      <div className="magic-link-content" onClick={handleIconClick}>
        <div className="magic-link-icon-text">
          <img
            src={magicLinkIcon}
            alt="Magic Link sign-in"
            className="btn-icon"
          />
          <span className="magic-link-text">Magic Link</span>
        </div>
        <input
          ref={inputRef}
          type="email"
          placeholder="Skriv inn e-posten din"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="magic-link-input"
          disabled={disabled}
          onClick={(e) => e.stopPropagation()} // Prevent div click when clicking on input
        />
      </div>
    </form>
  );
};

MagicLinkBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default MagicLinkBtn;
