import { useState, useMemo, useCallback } from 'react'; // Removed useEffect
import { useQuery } from 'react-query'; // Removed useQueryClient
import axiosInstance from '../config/axios.js';
import { useUser } from '../UserContext.js';
import { useFollowing } from './useFollowing.js';
import { QueryKeys } from '../constants/queryKeys.js';
import logger from '../utils/logger.js'; // Import the logger utility

export const fetchAllUsers = async () => {
  const url = `/api/getallusers`;

  const response = await axiosInstance.get(url);

  return response.data;
};

const useSearchUsers = (onUpdateFollowing) => {
  const [query, setQuery] = useState('');
  const { user } = useUser();
  const { data: followingList = [] } = useFollowing(user?.uid);

  // Fetch all users initially and store them in the cache
  const { data: allUsers = [], isLoading: allUsersLoading } = useQuery(
    QueryKeys.users.all,
    fetchAllUsers,
    {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      onError: (error) => {
        logger.error('Failed to fetch all users:', error);
      },
    }
  );

  // Filter users from the cache based on the search query
  const searchResults = useMemo(() => {
    if (!query) return [];

    return allUsers
      .filter((searchUser) => {
        const searchTerm = query.toLowerCase();
        return (
          (searchUser.name?.toLowerCase().includes(searchTerm) ||
            searchUser.email?.toLowerCase().includes(searchTerm) ||
            searchUser.Company?.toLowerCase().includes(searchTerm)) &&
          searchUser.uid !== user?.uid
        );
      })
      .map((user) => ({
        ...user,
        isFollowing: followingList.some((f) => f.uid === user.uid),
        onUpdateFollowing: async (isFollowing) => {
          try {
            await onUpdateFollowing?.(isFollowing, user.uid);
          } catch (error) {
            logger.error('Failed to update following status:', error);
            throw error;
          }
        },
      }));
  }, [query, allUsers, followingList, user?.uid, onUpdateFollowing]);

  const clearSearch = useCallback(() => {
    setQuery('');
  }, []);

  const handleSearch = useCallback((term) => {
    setQuery(term);
  }, []);

  return {
    searchResults,
    isLoading: allUsersLoading,
    handleSearch,
    clearSearch,
    currentUserId: user?.uid,
  };
};

export default useSearchUsers;
