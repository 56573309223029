import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PaymentCancelled.css';

const PaymentCancelled = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate('/Home');
  };

  return (
    <div className="payment-cancelled-container">
      <div className="payment-cancelled-card">
        <h2>Payment Cancelled</h2>
        <p>Your payment process was cancelled. No charges were made.</p>
        <button onClick={goBack}>Return to Dashboard</button>
      </div>
    </div>
  );
};

export default PaymentCancelled;
