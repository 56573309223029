import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Add PropTypes import
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig.js'; // Add .js extension
import { useQueryClient } from 'react-query';
import logger from './utils/logger.js';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!auth) {
      logger.error('Auth is not initialized');
      setLoading(false);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        const userData = {
          uid: firebaseUser.uid,
          email: firebaseUser.email,
          displayName: firebaseUser.displayName,
          photoURL: firebaseUser.photoURL,
        };

        setUser(userData);
        // Store user data and ID in queryClient cache for easy access
        queryClient.setQueryData(['auth', 'user'], userData);
        queryClient.setQueryData('currentUserId', userData.uid);
      } else {
        setUser(null);
        queryClient.setQueryData('currentUserId', null);
        queryClient.invalidateQueries(['auth']).catch(logger.error);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [queryClient]);

  const value = {
    user,
    setUser,
    loading,
    currentUserId: user?.uid || null,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export default UserContext;
