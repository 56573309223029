import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export default function SEOHelmet({
  title = '',
  description = 'The first social media for project management. Our mission: to make collaboration effortless. With PlanCoo, you connect, share, and align—all in one place.',
  image = null,
  url = null,
  includeAuthor = true, // Ny prop for å kontrollere visning av forfatterinfo
}) {
  // Sanitize inputs for additional security
  const safeTitle = title ? String(title).slice(0, 60) : '';
  const safeDescription = description
    ? String(description).slice(0, 160)
    : 'The first social media for project management. Our mission: to make collaboration effortless. With PlanCoo, you connect, share, and align—all in one place.';
  const siteTitle = 'PlanCoo';

  // Forfatterinformasjon
  const authorName = 'Dag Andre Daltveit';

  return (
    <Helmet>
      <title>{safeTitle ? `${safeTitle} | ${siteTitle}` : siteTitle}</title>
      <meta name="description" content={safeDescription} />

      {/* Forfatterinfo */}
      {includeAuthor && <meta name="author" content={authorName} />}

      {/* Siden serveren håndterer de fleste sikkerhetshodene, 
          beholder vi bare noen få her som backup */}
      <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
      <meta httpEquiv="X-XSS-Protection" content="1; mode=block" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={safeTitle || siteTitle} />
      <meta property="og:description" content={safeDescription} />
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />}
      {includeAuthor && <meta property="article:author" content={authorName} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={safeTitle || siteTitle} />
      <meta name="twitter:description" content={safeDescription} />
      {image && <meta name="twitter:image" content={image} />}
      {includeAuthor && <meta name="twitter:creator" content={authorName} />}

      {/* Viewport for responsivt design */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Schema.org markup for Google */}
      {includeAuthor && (
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: siteTitle,
            description: safeDescription,
            author: {
              '@type': 'Person',
              name: authorName,
            },
          })}
        </script>
      )}
    </Helmet>
  );
}

SEOHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  includeAuthor: PropTypes.bool,
};
