import React from 'react';
import PropTypes from 'prop-types';
import { IoSaveOutline } from 'react-icons/io5';
import { LuUndo2 } from 'react-icons/lu';
import { RiDeleteBinLine } from 'react-icons/ri';
import { IoIosAddCircleOutline } from 'react-icons/io';
import './TaskActionButtons.css';

const TaskActionButtons = ({
  onAdd,
  onUndo,
  onSave,
  onDelete,
  pendingChanges,
  isSaving,
  isLoading,
}) => {
  return (
    <div className="button-container-icons">
      <button
        className="btn-icon"
        onClick={onAdd}
        disabled={isSaving || isLoading}
        title="Add new task"
      >
        <IoIosAddCircleOutline size={24} />
      </button>

      <button
        className="btn-icon undo-button"
        onClick={onUndo}
        disabled={isSaving || isLoading || pendingChanges === 0}
        title="Undo changes"
      >
        <LuUndo2 size={24} />
        {pendingChanges > 0 && (
          <span className="undo-counter">{pendingChanges}</span>
        )}
      </button>

      <button
        className={`btn-icon ${pendingChanges > 0 ? 'btn-pending' : ''}`}
        onClick={onSave}
        disabled={isSaving || isLoading || pendingChanges === 0}
        title="Save changes"
      >
        <IoSaveOutline size={24} />
      </button>

      {onDelete && (
        <button
          className="btn-icon btn-delete"
          onClick={onDelete}
          disabled={isSaving || isLoading}
          title="Delete selected task"
        >
          <RiDeleteBinLine size={24} />
        </button>
      )}
    </div>
  );
};

TaskActionButtons.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  pendingChanges: PropTypes.number.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TaskActionButtons;
