import React from 'react';
import PropTypes from 'prop-types';
import './GoogleBtn.css';
import GoogleIcon from '../../../assets/Auth/Google.svg';

const GoogleBtn = ({ onClick, disabled }) => {
  return (
    <button className="custom-google-btn" onClick={onClick} disabled={disabled}>
      <img src={GoogleIcon} alt="Google sign-in button" className="btn-icon" />
    </button>
  );
};

GoogleBtn.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default GoogleBtn;
