import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AiFillLike,
  AiOutlineLike,
  AiOutlineSend,
  AiOutlineDelete,
} from 'react-icons/ai';
import LoadingPage from '../../Spinner/LoadingPage.js';
import Comment from './Comment.js';
import { usePostComment } from '../../../hooks/usePostComment.js';
import { useDeleteComment } from '../../../hooks/useDeleteComment.js';
import { useUser } from '../../../UserContext.js';
import { toast } from 'react-toastify';

const Post = ({
  post,
  handleDeletePost,
  handleLike,
  deleting,
  commentInputs,
  setCommentInputs,
}) => {
  const { mutate: postComment } = usePostComment();
  const { mutate: deleteComment } = useDeleteComment();
  const [imageLoaded, setImageLoaded] = useState(false);
  const { user } = useUser();

  const handleAddComment = (postId, commentText) => {
    if (commentText.trim().length > 500) {
      alert('Kommentaren kan ikke være lengre enn 500 tegn.');
      return;
    }

    if (!user?.uid) {
      alert('Du må være logget inn for å legge til en kommentar.');
      return;
    }

    postComment(
      {
        feed_id: postId,
        user_uid: user.uid,
        comment_text: commentText,
      },
      {
        onError: (error) => {
          console.error('Comment error:', error);
          toast.error('Kunne ikke legge til kommentar. Prøv igjen senere.');
        },
      }
    );

    setCommentInputs((prev) => ({
      ...prev,
      [postId]: '',
    }));
  };

  const handleDeleteComment = (comment_id, postId) => {
    const userUid = user?.uid;
    if (!userUid || !postId) return;

    const toastId = toast.warn(
      <div>
        <p>Er du sikker på at du vil slette denne kommentaren?</p>
        <div className="toast-buttons">
          <button
            onClick={() => {
              deleteComment(
                { comment_id, userUid, feed_id: postId },
                {
                  onError: (error) => {
                    toast.error(
                      error.response?.data?.message ||
                        'Kunne ikke slette kommentaren'
                    );
                  },
                }
              );
              toast.dismiss(toastId);
            }}
            className="confirm-button"
          >
            Ja, slett
          </button>
          <button
            onClick={() => toast.dismiss(toastId)}
            className="cancel-button"
          >
            Avbryt
          </button>
        </div>
      </div>,
      {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        closeButton: false,
      }
    );
  };

  const hasValidComments = post?.comments && Array.isArray(post.comments);

  return (
    <div className="post">
      <div className="post-header">
        <img
          src={post.userImage}
          alt={`${post.username}'s profile`}
          className="post-user-image"
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
        <div className="post-user-details">
          <span className="post-username">{post.username}</span>
          {post.userCompany && (
            <span className="post-user-company">{post.userCompany}</span>
          )}
        </div>
        {user?.uid === post.userUid && (
          <button
            onClick={() => handleDeletePost(post.id)}
            className="delete-button"
            disabled={deleting}
          >
            {deleting ? (
              <LoadingPage />
            ) : (
              <AiOutlineDelete size={20} style={{ marginRight: '5px' }} />
            )}
          </button>
        )}
      </div>
      <div className="post-description">{post.description}</div>
      {post.imageUrl && post.imageUrl !== 'pending' && (
        <div className="post-image-container">
          {!imageLoaded && <div className="post-image-skeleton"></div>}
          <img
            src={post.imageUrl}
            alt="Post content"
            className={`post-image ${!imageLoaded ? 'loading' : ''}`}
            onLoad={() => setImageLoaded(true)}
            onError={(e) => {
              console.error('Image load error:', post.imageUrl);
              e.target.style.display = 'none';
              setImageLoaded(true);
            }}
          />
        </div>
      )}
      <div className="post-meta">
        <span className="post-created-at">
          {new Date(post.created_at).toLocaleDateString('no-NO', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          })}
        </span>
      </div>
      <div className="post-actions">
        <button
          className={`like-button ${post.liked_by_current_user ? 'liked' : ''}`}
          onClick={() => handleLike(post.id)}
        >
          {post.liked_by_current_user ? <AiFillLike /> : <AiOutlineLike />}
          <span>{post.likes_count}</span>
        </button>
      </div>

      <div className="post-comments">
        {hasValidComments && post.comments.length > 0 ? (
          post.comments.map((comment) => (
            <Comment
              key={comment.comment_id || `temp-${comment.id}`}
              comment={comment}
              feed_id={post.id}
              handleDeleteComment={handleDeleteComment}
              deleting={deleting}
            />
          ))
        ) : (
          <p className="no-comments">Ingen kommentarer ennå.</p>
        )}
      </div>

      <div className="comment-box">
        <input
          type="text"
          placeholder="Legg til en kommentar..."
          value={commentInputs[post.id] || ''}
          onChange={(e) =>
            setCommentInputs((prev) => ({
              ...prev,
              [post.id]: e.target.value,
            }))
          }
          className="comment-input"
        />
        <button
          onClick={() => {
            const commentText = commentInputs[post.id];
            if (commentText) {
              handleAddComment(post.id, commentText);
            }
          }}
          className="comment-button"
        >
          <AiOutlineSend size={20} style={{ marginRight: '5px' }} />
        </button>
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  handleLike: PropTypes.func.isRequired,
  deleting: PropTypes.bool.isRequired,
  commentInputs: PropTypes.object.isRequired,
  setCommentInputs: PropTypes.func.isRequired,
};

export default Post;
