import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Flag from 'react-flagkit';
import './LanguageSelector.css';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('');

  // Bestem standardspråket basert på domenet ved komponentoppstart
  useEffect(() => {
    const hostname = window.location.hostname;
    const initialLanguage = hostname.includes('plancoo.no') ? 'no' : 'en';

    // Sett språk hvis det ikke allerede er satt
    if (!selectedLanguage) {
      setSelectedLanguage(initialLanguage);
      i18n.changeLanguage(initialLanguage);
    }
  }, [i18n, selectedLanguage]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  const getLanguageName = (lng) => {
    switch (lng) {
      case 'en':
        return 'English';
      case 'no':
        return 'Norsk';
      default:
        return 'Language';
    }
  };

  const getFlagCode = (lng) => {
    switch (lng) {
      case 'en':
        return 'GB';
      case 'no':
        return 'NO';
      default:
        return '';
    }
  };

  return (
    <DropdownButton
      id="dropdown-basic-button"
      title={
        <div className="language-button">
          <Flag
            country={getFlagCode(selectedLanguage)}
            className="language-flag"
          />
          {getLanguageName(selectedLanguage)}
        </div>
      }
      className="dropdown-button-custom"
    >
      <Dropdown.Item
        onClick={() => changeLanguage('en')}
        className="dropdown-item-custom"
      >
        <Flag country="GB" size={24} style={{ marginRight: '8px' }} /> English
      </Dropdown.Item>
      <Dropdown.Item
        onClick={() => changeLanguage('no')}
        className="dropdown-item-custom"
      >
        <Flag country="NO" size={24} style={{ marginRight: '8px' }} /> Norsk
      </Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSelector;
