export const QueryKeys = {
  auth: {
    all: ['auth'],
    profile: (uid) => ['auth', 'profile', uid],
    user: ['auth', 'user'],
    session: ['auth', 'session'],
    emailForSignIn: ['auth', 'emailForSignIn'], // Legg til for magic link
  },
  profile: {
    all: ['profile'],
    detail: (uid) => ['profile', uid],
    settings: (uid) => ['profile', 'settings', uid],
    name: (uid) => ['profile', 'name', uid],
    company: (uid) => ['profile', 'company', uid],
    profilePic: (uid) => ['profile', 'profilepic', uid],
    create: (uid) => ['profile', 'create', uid], // Legg til for brukeropprettelse
    update: (uid) => ['profile', 'update', uid], // Legg til for profiloppdatering
  },
  currentUser: {
    all: ['currentUser'],
    profile: ['currentUser', 'profile'],
    settings: ['currentUser', 'settings'],
    data: (key) => ['currentUser', 'data', key], // For spesifikke brukerdata-felter
  },
  following: {
    all: ['following'],
    list: (uid) => ['following', 'list', uid],
    detail: (followerId, followedId) => ['following', followerId, followedId],
  },
  followers: {
    all: ['followers'],
    list: (uid) => ['followers', 'list', uid],
  },
  users: {
    all: ['users', 'all'],
    search: (query) => ['users', 'search', query],
    detail: (uid) => ['users', 'detail', uid],
  },
  feed: {
    all: ['feed'],
    list: (page) => ['feed', 'list', page],
    detail: (id) => ['feed', 'detail', id],
    comments: (feedId) => ['feed', 'comments', feedId],
  },
  tasks: {
    list: () => ['tasks'],
  },
};

// Helper functions for working with query keys
export const getQueryKeyParts = (queryKey) => {
  if (Array.isArray(queryKey)) {
    return {
      scope: queryKey[0],
      action: queryKey[1],
      id: queryKey[2],
    };
  }
  return null;
};

export const createQueryKey = (scope, action, id) => {
  return [scope, action, id].filter(Boolean);
};
